.rating {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.rating > label {
  height: 20px;
  width: 20px;
  mask: url("../img/icons/star-1.svg") no-repeat 50% 50%;
  -webkit-mask: url("../img/icons/star-1.svg") no-repeat 50% 50%;
  cursor: pointer;
}

.rating > label {
  background-color: var(--grey1);
}

.rating > input {
  display: none;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  background-color: var(--yellow);
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  background-color: var(--yellow);
}
