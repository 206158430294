.disPlayCard{
  width: 100%;
	
  background: rgb(0,24,180);
  background: -moz-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0018b4",endColorstr="#035af4",GradientType=1);

	border-radius: 18px;
	padding: 20px;
	box-shadow: var(--shadow-card);

	display: flex;
	flex-direction: row;
  align-items: center;
	gap: 15px;

  overflow: hidden;
}


.displayName{
  color: var(--white);
}

.displayName p{
  font: 1.8rem var(--fontGeneral);
  font-weight: 600;
}

.displayName span{
  font: 1.2rem var(--fontGeneral);
}

.ImagenPerfil {
  border: 3px solid var(--primary-cl);
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.ImagenPerfil img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ImagenPerfil:hover .PerfilButton {
  opacity: 1;
}

.PerfilButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.PerfilButton button {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.PerfilButton button img {
  height: 30px;
  width: 30px;
  filter: invert(1) brightness(10);
}
