.menuMobile{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  z-index: 5;
}

.menuBottom{
  height: 60px;
  width: 100%;
  border-radius: 30px;
  background: var(--card);
  box-shadow: 0 3px 20px rgba(0,0,0,.05), 0 0px 30px rgba(0,0,0,.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItemMobile{
  width: 20%;
}

.menuItemMobile a{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font: 1rem var(--fontGeneral);
  color: var(--icon);
}

.menuItemMobile a svg{
  stroke: var(--icon);
}

.menuItemAct svg {
  stroke: var(--secondary) !important;
}

.menuItemAct {
  color: var(--secondary) !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .menuMobile{
    display: none;
  }
}