.cardFechas{
  display: flex;
  gap: 20px;
}

.SearchBox{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.flex50{
  width: calc(50% - 5px);
}

.filtersDiv{
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  border: 1px solid var(--grey-md);
}

.filterFlex2{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

.filterFlex1{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.showMobile{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.showDesk{
  width: 100%;
  display: none;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

  .showMobile{
    width: 100%; 
    display: none;
  }
  .showDesk{
    width: 100%; 
    display: flex;
  }

  .filtersDiv{
    border-radius: 0px;
    padding: 0px;
    gap: 20px;
    border: none;
  }


  .filterFlex2{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }
  
  .filterFlex1{
    
    flex-wrap: nowrap;
    gap: 10px;
  }
  
}

@media (min-width: 1200px) {
}
