.contSumsub{
    width: 100%;
    padding: 80px 15px 50px 15px;
    background: var(--background);

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contInnerSumsub{
    background: var(--card);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
    min-height: 400px;
    margin: 0 auto;
}

.cardAsessor{
    background: var(--card);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    font: 1.6rem var(--fontGeneral);
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ConTLoad{
    width: 100%;
    min-width: 300px;
    min-height: 400px;
    display: flex;
    align-items: center;
}

.contAnunce{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 500px;
    min-height: 400px;
    border-radius: 10px;
    padding: 50px 50px 50px 50px;
    margin: 0 auto;
    background-color: var(--blanco);
}

.textAnuncio{
    color: var(--negro);
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    text-align: left;
}

.AnuncioTitle{
    color: var(--negro);
    font: 2rem var(--fontSt);
    font-weight: 700;
    text-align: left;
}

.headerAsesor{
    width: 100%;
    padding: 20px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.headerAsesor img{
    width: 60px;
}

.ImgBusiness{
    width: 100px !important;
    border-right: 1px solid var(--grey);
    padding-right: 20px;
}

@media (min-width: 576px) {}
@media (min-width: 768px) {}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}
    