.cardConfig{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemConfig{
  display: flex;
  gap: 10px;
  align-items: center;
  font: 1.4rem var(--fontGeneral)
}

.itemConfig svg{
  width: 15px;
}

.coinActive{
  cursor: pointer;
}

.coinDisable{
  filter: grayscale(1);
}
