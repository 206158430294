.ReviewBox{
  padding: 20px 0;
  background: var(--primary-lg);
  align-items: center;
}

.carouselReviews{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.carouselReviews ul{
  align-items: center !important;
}

.DivNewTit{
  text-align: center;
}

.HeadSec{
  color: var(--blanco);
  font: 2rem var(--fontGeneral);
  font-weight: 500;
}

.HeadSec span{
  display: block;
  font: 3rem var(--fontGeneral);
  font-weight: 900;
  color: var(--amarillo);
}

.review{
  position: relative;
  width: 100%;
  padding: 30px 30px 30px 30px;
  font-weight: 600;
  color: var(--blanco);
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewComent{
  position: relative;
  max-width: 500px;
  font: 1.8rem var(--fontGeneral);
  font-weight: 500;
  margin-bottom: 20px;
  border: 3px solid var(--gris-lg);
  padding: 30px;
  border-radius: 10px;
}

.reviewComent::after{
  content: '';
  position: absolute;
  bottom: -15px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  background: var(--primary-lg);
  border: 3px solid var(--gris-lg);
  transform: rotate(45deg);
}

.reviewComent::before{
  content: '';
  position: absolute;
  bottom: 0px;
  margin-left: -20px;
  width: 40px;
  height: 22px;
  background: var(--primary-lg);
  z-index: 2;
}

.reviewName{
  width: 100%;
  font: 2.2rem var(--fontGeneral);
  font-weight: 700;
  margin-top: 10px;
}

.reviewStars{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewStars div img{
  height: 20px;
  margin: 0 1px;
  filter: invert(80%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.reviewDate{
  width: 100%;
  font: 1.2rem var(--fontGeneral);
  font-weight: 300;
}

@media (min-width: 992px) {
  .ReviewBox{
      padding: 50px 0;
  }

  .HeadSec{
      font: 2.2rem var(--fontGeneral);
    }

  .HeadSec span{
      font: 4rem var(--fontGeneral);
      font-weight: 900;
  }

  .reviewComent{
      padding: 50px;
      font: 2rem var(--fontGeneral);
      font-weight: 500;
      border: 2px solid var(--gris-lg);
  }
}

