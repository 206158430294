.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBack{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.9;
  z-index: 1;
}

.scrollModal{
  overflow-y: auto;
  height: 100%;
}

.modalContent{
  padding: 60px 15px 60px 15px;
  z-index: 2;
}

.notice{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.noticeCont{
  width: 100%;
  max-width: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: var(--primary);
  z-index: 2;
}

.noticeCont img{
  width: 100%;
  border-radius: 15px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.noticeCont svg{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
  stroke: #ffffff;
  cursor: pointer;
}

.modalPopUp{
  padding: 40px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background: var(--card);
  border-radius: 10px;
}

.modalPopUp h1{
  margin: 0;
  color: var(--headColor);
  font: 2rem var(--fontGeneral);
  font-weight: 900;
}

.modalPopUp p{
  max-width: 240px;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
}

.modalButtons{
  display: flex;
  gap: 20px;
}

.IconModal svg{
  stroke: var(--primary);
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {

.modal{
  align-items: center;
}

.modalContent{
  padding: 0px;
}

}