.cardLink{
  width: 100%;
  background: var(--card);
  border-radius: 18px;
  padding: 30px 20px;
  box-shadow: var(--shadow-card);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.cardLink h3{
  font: 1.8rem var(--fontGeneral);
  font-weight: 900;
}

.cardLink p{
  font: 1.4rem var(--fontGeneral);
  text-align: center;
  max-width: 400px;
}

.cardListItem{
 
  display: flex;
  gap: 10px;
  border: 1px dashed var(--grey);
  border-radius: 10px;
  padding: 10px;
}

.cardListItem p{
  width: 95px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardListItem p span{
  font: 1.1rem var(--fontGeneral);
  font-weight: 600;
}

/*CARD INICIO*/

.minTrans{
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 1.4rem var(--fontGeneral);
  color: var(--textColor);

  border: 1px solid var(--grey);
  border-radius: 8px;
  padding: 10px;
}

.minTrans h3{
  color: var(--primary);
  font-weight: 900;
}

.itemsMinTrans{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.itemsMinTrans div{
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemsMinTrans div p{
  display: flex;
  flex-direction: column;
}

.minTrans div p span{
  font: 1.1rem var(--fontGeneral);
}

.minTrans div svg{
  width: 20px;
  height: 20px;
  stroke: var(--grey);
}