.btnDisabled, .btnGeneral {
  position: relative;

  padding: 15px 12px;
  height: 40px;
  border-radius: 10px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: transparent;
  color: var(--grey);
  font: 1.5rem var(--fontGeneral), sans-serif;
  font-weight: 400;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btnGeneral svg{
  stroke: var(--grey);
  height: 20px;
}
.btnDisabled svg {
  stroke: var(--white);
  height: 20px;
}

.btnDisabled{
  color: var(--white);
  background: var(--grey);
  cursor: not-allowed;
}

.primaryC{
  color: #fff;
  background: var(--primary);
}

.primaryC svg{
  stroke: var(--white);
}

.yellowC {
  color: var(--primary);
  background: var(--yellow);
  font-weight: 700;
}

.primaryC:hover{
  box-shadow: var(--cardShadowLg);
}

.whiteC{
  color: var(--primary);
  background: var(--white);
}

.whiteC svg{
  stroke: var(--primary);
}

.whiteC:hover{
  box-shadow: var(--cardShadowWt);
}

.redC{
  color: #fff;
  background: var(--red);
}

.redC svg{
  stroke: var(--white);
}

.redC:hover{
  box-shadow: var(--ShadowRed);
}

.ButtonLoadDiv{
  padding: 0 5px;
}

.btnText{
  background: transparent;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral), sans-serif;
  font-weight: 400;
}

.Button{
  padding: 22px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  background: var(--primary);
  color: var(--white);
  font: 1.5rem var(--fontHeader), sans-serif;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 0;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Button:hover,
.Button:focus{
  background: var(--primary-lg);
  color: var(--white);
}

.btnLarge{
  width: 100%;
}

.buttonLink{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 16px;
  gap: 8px;

  height: 48px;

  background: var(--primary);
  box-shadow: var(--cardShadowLg);
  border-radius: 24px;

  font-family: var(--fontGeneral);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--white);
}

.BtnIcon{
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
}

.BtnIcon svg{
  width: 25px;
  height: 25px;
  stroke: var(--icon);
}

.BtnBox{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.BtnExports{
  position: relative;

  display: flex;
  align-items: center;
  padding: 5px 12px;
  border-radius: 10px;
  gap: 10px;
  background: var(--grey-lg);
  color: var(--icon);
  stroke: var(--icon);

  font: 1.2rem var(--fontGeneral);
  font-weight: 500;
}

.BtnExports svg{
  width: 15px;
}

.GreenColor:hover{
  background: var(--green) !important;
  color: #FFFFFF;
  stroke: #FFFFFF;
}

.RedColor:hover{
  background: var(--red) !important;
  color: #FFFFFF;
  stroke: #FFFFFF;
}

.BlueColor:hover{
  background: var(--primary-lg) !important;
  color: #FFFFFF;
  stroke: #FFFFFF;
}

.btnCheckA{
  background: transparent;
  stroke: var(--green) !important;
  color: var(--green) !important;
}

.btnCheck{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background: transparent;
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
  color: var(--icon);
  stroke: var(--icon);
}

.btnWhatsapp{
  background: var(--icon);
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnWhatsapp:hover{
  background: var(--green);
}

.btnWhatsapp:hover svg{
  fill:var(--white);
}

.btnWhatsapp svg{
  height: 20px;
  fill: var(--grey);
}

.btnLink{
  background: var(--icon);
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnLink svg{
  stroke:  var(--grey);
}
.btnLink:hover {background: var(--primary);}
.btnLink:hover svg{
  stroke:  var(--white);
}

.linkWhatsapp{
  background: var(--green);
  padding: 15px 12px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: var(--white);
  font: 1.4rem var(--fontGeneral), sans-serif;
}

.linkWhatsapp svg{
  height: 20px;
  width: 20px;
  fill:  var(--white);
}

.btnRefer{
  padding: 30px 20px;
  height: 40px;
  border-radius: 10px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font: 1.4rem var(--fontGeneral), sans-serif;
  font-weight: 400;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  color: var(--secondary);
  background: var(--primary-cl);
}

.btnRefer svg{
  stroke: var(--secondary);
}

.btnRefer:hover,
.btnRefer:focus{
  background: var(--primary-lg);
  color: var(--white);
}

.btnRefer:hover svg,
.btnRefer:focus svg{
  stroke: var(--white);
}

.bubblePop{
  position: absolute;
  top: -7px;
  right: -5px;
  height: 15px;
  width: 15px;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 0.9rem var(--newFont);
  font-weight: 600;
  border: 1px solid var(--red);
  background: var(--red-lg);
  color: var(--red);
  z-index: 1;
}