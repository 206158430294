.newCalc{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.warningCalculator{
  font: 1.2rem var(--fontGeneral);
  background: var(--red-lg);
  color: var(--red);
  padding: 10px;
  border-radius: 10px;
}

.optItem{
  display: flex;
}

.optItem span{
  font-weight: 600;
}

.optsCalc{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.optCalc{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font: 1.4rem var(--fontGeneral);
}

.optCalcCheck{
  border-bottom: 1px solid var(--grey);
}

.optCalc svg{
  height: 15px;
  width: 18px;
  stroke: var(--grey);
}

.optCalc p{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.optCalc p b{
  margin: 0px 5px;
}

.selectCalc{
  position: relative;
  min-height: 25px;
  cursor: pointer;
}

.optionsCalc{
  position: absolute;
  right: 0px;
  top: 24px;
  width: 200px;
  height: auto;
  max-height: 160px;
  margin: 0 !important;
  border-radius: 10px;
  background-color: var(--card);
  border-top: solid 1px var(--grey);
  border-left: solid 1px var(--secondary);
  border-right: solid 1px var(--secondary);
  border-bottom: solid 1px var(--secondary);
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 999;
}

.optionCalc{
  padding: 10px 12px;
  margin: 0;
  margin: 0 !important;
  border-bottom: solid 1px var(--grey-md);
  font: 1.4rem var(--fontGeneral);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--textColor);
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.optionCalcNo{
  position: relative;
  background: var(--grey-lg);
  cursor: not-allowed;
}

.optionCalcNo::after{
  position: absolute;
  padding: 0px 10px;
  right: 0px;
  top: 10px;
  content: 'No Disponible';
  font: 1.2rem var(--fontGeneral);
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  z-index: 3;
}

.optionCalc:last-child{
  border-bottom: none;
}

.optionCalc:hover{
  background: var(--secondary);
  color: var(--white);
}

.simbolb{
  height: 20px;
  width: 20px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  color: var(--grayA);
  background: var(--grey-lg);
  border-radius: 100%;
}

.reverseFlex{
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}

.reverseNo{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/*CALCULADORA COIN*/

.selectCoin{
  position: relative;
  height: 40px;
}

.CoinArrowDown{
  position: absolute;
  right: 10px;
}

.CoinArrowDown svg{
  height: 20px;
  stroke: var(--secondary);
}

.coinChoosed{
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  font: 1.2rem var(--fontGeneral);
  color: var(--primary-lg);
  background: var(--primary-cl);
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid var(--primary-cl);
  border-width: 1px 1px 0px 1px;
  cursor: pointer;
}

.borderTop{
  border-radius: 10px 10px 0px 0px;
  border: 1px solid var(--secondary);
  border-width: 1px 1px 0px 1px;
}

.coinChoosed svg{
  width: 23px;
}

.arrowCoin{
  height: 15px;
  width: 15px !important;
  stroke: var(--primary-lg);
}

.coinsOptions{
  width: 100%;
  background: var(--white);
  box-shadow: var(--shadow-card);
  position: absolute;
  font: 1.2rem var(--fontGeneral);
  top: 40px;
  left: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 10px 10px;
  border: solid 1px var(--secondary);
  overflow: hidden;
  z-index: 2;
}

.coinOption{
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid var(--grey-lg);
  cursor: pointer;
}

.coinOptionUnable{
  background: var(--grey-lg);
  color: var(--grayA);
  cursor: not-allowed;
}

.coinOptionUnable:hover{
  background-color: var(--grayA) !important;
}

.coinOptionUnable svg{
  filter: saturate(0);
}

.labelUnabled{
  padding: 2px 6px;
  color: var(--white);
  background: var(--red);
  font: 0.9rem var(--fontGeneral);
}

.coinOption:last-child{
  border-bottom: none;
}

.coinOption:hover{
  background: var(--secondary);
  color: var(--white);
}

.coinOption:hover .arrowCoin{
  stroke: var(--white);
}

.coinOption svg{
  width: 23px;
}

.free{
  background: var(--red);
  color: var(--white);
  padding: 0px 5px;
}