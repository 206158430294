.LabelResumen{
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  color: var(--green-dk);
  background: var(--green-lg);
  box-shadow: var(--BoxShadow);
  border: 1px solid var(--green-dk);
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
}

.LabelResumen span{
  font-weight: 600;
}

.Resumenes{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.Resumenes::-webkit-scrollbar{
  display: none;
}

.ResumeCard{
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  gap: 10px;
  flex-shrink: 0;
  padding: 20px;

  background: var(--card);
  box-shadow: var(--BoxShadow);

  border-radius: 20px;
}

.ResumeCard div{
  display: flex;
  gap: 15px;
}

.ResumeCard div div{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;
  padding: 10px;
  border-radius: 30px;
  background: var(--primary-cl);
}

.ResumeCard div div svg{
  stroke: var(--secondary);
}

.ResumeCard div p{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  color: var(--label);

  font: 1.2rem var(--fontGeneral);
  font-weight: 500;
}

.ResumeCard div p span{
  color: var(--textColor);
  font: 1.6rem var(--fontGeneral);
  font-weight: 700;
}

.ResumeCard p{
  color: var(--textColor);
  font: 1.2rem var(--fontGeneral);
}
.ResumeCard p span{

  font-weight: 700;
}

.markGreen{
  color: var(--green) !important;
}

.markRed{
  color: var(--red) !important;
}

@media (min-width: 992px) {
  .ResumeCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    gap: 10px;
    flex-shrink: 1;
    padding: 20px;
  }
}