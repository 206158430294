.dashboard{
  position: relative;
  background: var(--background);
}

.blockscroll{
  height: 100vh;
  overflow: hidden;
}

.sideFix{
  padding: 0;
}

.dashContent{
  position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 80px 15px 90px 15px;
	display: flex;
  flex-direction: column;
  align-items: center;
}

.dashInner{
  width: 100%;
  max-width: 1300px;
  padding: 0px;
}

.dashPage{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card{
	width: 100%;
	background: var(--card);
	border-radius: 18px;
	padding: 20px;
	box-shadow: var(--shadow-card);

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.cardForm{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/*FLEX*/

.cardFlex{
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: space-between;
}

.columns3{
	justify-content: stretch;
}

.column3{
	width: 100%;
	height: auto;
	align-self: stretch;
}

.card70{
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.card30{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: stretch;
}

.formCard{
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.cardGaps{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CheckBoxs{
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.checkboxDiv{
  display: flex;
  gap: 40px;
}

.divPep{
  border-radius: 10px;
  background: var(--grey2);
  padding: 10px;
  position: relative;
}

.divPepWarn{
  border-radius: 10px;
  background: var(--red-lg);
  padding: 10px;
  position: relative;
}

.itemsEmpty{
	height: 100%;
	padding: 50px 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 15px;
	background: var(--card);
	border: 2px dotted var(--grey);
	font: 1.4rem var(--fontGeneral);
	font-weight: 600;
	color: var(--grey);
	text-align: center;
}

.itemsEmpty span{
	display: block;
	font-weight: 400;
}

.pageTitle{
  display: none;
  color: var(--grey);
  font: 2rem var(--fontGeneral);
  font-weight: 600;
}

.cardTitle{
  color: var(--grey1);
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.cardTitle svg{
  width: 20px;
}

.cardData{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.cardData100{
  width: 100% !important;
}

.cardData div{
  width: 50%;
}

.cardData div img{
  width: 100%;
}

.cardData label{
  display: block;
  color: var(--grey1);
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}

.cardData a{
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
  font-weight: 700;
}

.cardData p{
  width: 100%;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.docItem{
  display: flex;
}

.docItem a{
  padding: 50px;
  background: var(--background);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
  cursor: pointer;
}

.docItem svg{
  height: 25px;
  stroke: var(--icon);
}

.cardContact{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	cursor: pointer;
}

.cardContact div{
	display: flex;
	align-items: center;
	gap: 10px;
}

.cardContact div p{
	font: 1.4rem var(--fontGeneral);
	color: var(--textColor);
}

.cardContact div p span{
	font: 1.1rem var(--fontGeneral);
	display: block;
}

.cardIngreso{
  font: 1.4rem var(--fontGeneral);
  padding: 14px;
  border: 1px solid var(--grey2);
  border-radius: 10px;
  color: var(--textColor);
}

.cardIngreso label{
  color: var(--green);
  font-weight: 900;
}

.cardRevisiones{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.cardResumen{
  font: 1.4rem var(--fontGeneral);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardResumen div{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.cardResumen label{
  color: var(--icon);
}

.cardResumen span{
  text-align: right;
}

/*DOCUMENT*/

.docPDF{
  width: 100%;
  background: var(--red-lg);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font: 2rem var(--fontGeneral);
  font-weight: 900;
  color: var(--red);
}
.docPDF svg{
  stroke: var(--red);
}

.cardDocuments{
  width: 100%;
  display: flex;
  justify-content: stretch;
  gap: 20px;
}

.cardBank{
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  background: var(--primary-cl);
  font: 1.2rem var(--fontGeneral);
  border-radius: 10px;
}

.cardBank img{
  border-radius: 15px;
  width: 50px;
}

.cardBank h2{
  margin: 0;
}

.linkCard{
  display: flex;
  gap: 20px;
}

.linkCard a{
  border: 1px solid var(--secondary);
  border-radius: 10px;
  padding: 15px;
  font: 1.6rem var(--fontGeneral);
  color: var(--secondary);
  background-color: var(--primary-cl);
}

.linkCard a:hover{
  background-color: var(--secondary);
  color: var(--white);
}

.cardFlex2{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardFlex2Div{
  width: 100%;
}

.cardFlex3{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardFlex3Div{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 576px) {
  .cardData div{
    width: calc(50% - 15px);
  }
}

@media (min-width: 768px) {

  .cardData div{
    width: 30%;
  }

	.columns3{
		justify-content: space-between;
	}
	
	.column3{
		width: calc(50% - 10px);
		height: auto;
		align-self: stretch;
	}

  .card70{
    width: 100%;
  }

  .card30{
    width: 100%;
  }
}

@media (min-width: 992px) {

  .cardData div{
    width: 30%;
  }

	.dashContent{
    position: relative;
		width: 100%;
    padding: 60px 0px 0px 60px;
	}

  .blockscroll{
    height: inherit;
    overflow: auto;
  }

  .dashInner{
    width: 100%;
    padding: 30px;
  }

  .pageTitle{
    display: block;   
  }

  .sideFix{
    padding-left: 296px !important;
  }

  .scrollComponent::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  } 
      
  .scrollComponent::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to left, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }
    
  .scrollComponent::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
    
  .scrollComponent::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
    border: 1px solid #ccc;
  }
    
  .scrollComponent::-webkit-scrollbar-thumb:active {
    background: linear-gradient(to left, #22ADD4, #1E98BA);
  }

  .card{
    padding: 30px;
  }

  .card70{
    width: calc(70% - 20px)
  }

  .card30{
    width: 30%;
  }

  .CheckBoxs{
    justify-content: flex-end;
  }

  .cardFlex2Div{
    width: calc(50% - 10px);
  }

  .cardFlex3Div{
    width: calc(100%/3 - 20px);
  }
}

@media (min-width: 1200px) {

	.columns3{
		justify-content: stretch;
	}
	
	.column3{
		width: calc(100% / 3 - 14px);
		height: 100%;
		align-self: stretch;
	}
}

@media (min-width: 1400px) {

}

