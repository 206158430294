.countDown {
  position: relative;
  width: 100%;
  height: 35px;
  border-radius: 50px;
  background: var(--primary);
  font: 2rem var(--fontGeneral);
  font-weight: 700;
  overflow: hidden;
}

.countWhite {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}

.countProgress {
  top: 0;
  left: 0;
  height: 100%;
  background: var(--grey);
  overflow: hidden;
}

.countBlue {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  z-index: 1;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
