.pendingsCards{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pendingsItem{
  display: flex;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--grey);

  cursor: pointer;
}

.pendingsItem p{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  font: 1rem var(--fontGeneral);
}

.pendingsItem p span{
  font: 1.2rem var(--fontGeneral);
  font-weight: 700;
}

.itemPreparado{
  background: var(--green-lg) !important;
  border:  1px solid var(--green-dk) !important;
  color: var(--green-dk);
}