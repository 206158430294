.itemCd2{
  position: relative;
  width: 100%;
  padding: 20px 15px;
  font: 1.4rem var(--fontGeneral);
  color: var(--textColor);
  border: 1px solid var(--grey1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer
}

.itemCd2:hover{
  border: 1px solid var(--primary-lg);
  background: var( --primary-cl);
  color: var(--primary-lg);
}

.itemCd2 label{
  position: absolute;
  left: 15px;
  top: -9px;
  font: 1rem var(--fontGeneral);
  background: var(--textColor);
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
}

.itemCd2:hover label{
  background: var(--primary-lg);
}

.itemCd2 span{
  font: 2rem var(--fontGeneral);
  font-weight: 900;
}

.itemCd2Btns{
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  gap: 10px;
}

.itemCd2AddBtn{
  width: 100%;
  padding: 20px 15px;
  font: 1.4rem var(--fontGeneral);
  color: var(--grey1);
  border: 1px dashed var(--grey1);
  background: var(--grey2);
  border-radius: 10px;
}

.itemCd2AddForm{
  padding: 20px 15px;
  border-radius: 10px;
  border: 1px dashed var(--grey1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.listItMul{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.listItMul div{
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey1);
}

.listItMul div:last-child{
  border-bottom: none;
}