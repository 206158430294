.headerDash{
  position: fixed;
  top: 0;
  left: 0px;
 
  height: 60px;
  width: 100%;
  padding: 0px 15px;

  display: flex;
  align-items: center;

  background-color: var(--card);
  border-bottom: 1px solid var(--border);

  z-index: 98;
}

.headerPlus{
  left: 0px;
}

.mobileOptions{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desktopOptions{
  display: none;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {

  .headerDash{
    left: 296px;
  }

  .headerPlus{
    left: 60px !important;
  }

  .mobileOptions{
    display: none;
  }

  .desktopOptions{
    display: flex;
  }
}

@media (min-width: 1200px) {

}
