.newTarget{
  position: relative;
  min-height: 60vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flag{
  font-family: 'flags'
}

.newTarget{
  background: var(--primary);
  background: -moz-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, var(--primary)),
    color-stop(100%, var(--primary-lg))
  );
  background: -webkit-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -o-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -ms-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#035af4', GradientType=0 );
}

.newTarget h1{
  max-width: 550px;
  color: var(--white);
  font: 2.8rem var(--newFont);
  text-align: center;
  font-weight: 600;
}

.newTarget h1 span{
  color: var(--yellow);
  font-weight: 900;
}

.newTarget h2{
  margin-top: 20px;
  color: var(--white);
  font: 1.8rem var(--newFont);
  text-align: center;
  font-weight: 400;
}

.cardTarget{
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #000942;
}

.cardTarget p{
  max-width: 500px;
  text-align: center;
  color: var(--white);
  font: 1.4rem var(--newFont);
  font-weight: 400;
}

.cardBanner{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.indexCalc{
  width: 100%;
}

.bannerText{
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.bannerText p{
  font: 2.6rem var(--newFont);
  font-weight: 900;
  color: var(--white);
}

.cardsCountryIndex{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flagsItem{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--primary-cl);
  color: var(--primary-lg);
  border-radius: 10px;
  font: 1.4rem var(--newFont);
  font-weight: 400;
}

.flagsItem svg{
  width: 25px;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .newTarget h1{
    max-width: 660px;
    font: 3.4rem var(--newFont);
    font-weight: 900;
  }

  .newTarget h2{
    font: 2.4rem var(--newFont);
  }

  .bannerText{
    padding: 40px 0px;
  }

  .cardTarget p{
    max-width: 500px;
    font: 1.6rem var(--newFont);
  }
}

@media (min-width: 992px) {
  .newTarget h1{
    max-width: 700px;
    font: 3.8rem var(--newFont);
    font-weight: 900;
    text-align: left;
  }

  .newTarget h2{
    font: 2.6rem var(--newFont);
  }

  .cardTarget p{
    text-align: left;
    max-width: 500px;
    font: 1.6rem var(--newFont);
  }

  .cardBanner{
    padding: 30px 0px;
    gap: 40px;
    justify-content: space-around;
  }
  .indexCalc{
    width: 50%;
    max-width: 450px;
  }

  .bannerText{
    width: 50%;
    max-width: 450px;
    align-items: start;
  }

  .bannerText p{
    text-align: left;
  }

}

@media (min-width: 1200px) {

  .newTarget{
    min-height: 70vh;
  }

  .newTarget h1{
    max-width: 870px;
    font: 4rem var(--newFont);
    font-weight: 900;
  }

  .newTarget h2{
    font: 2.8rem var(--newFont);
  }

  .cardTarget p{
    max-width: 600px;
    font: 1.6rem var(--newFont);
  }

  .bannerText{
    max-width: 550px;
  }
}

@media (min-width: 1370px) {

  .newTarget h1{
    max-width: 1000px;
    font: 4.7rem var(--newFont);
    font-weight: 900;
  }

  .newTarget h2{
    font: 3rem var(--newFont);
  }

  .cardTarget p{
    max-width: 600px;
    font: 1.6rem var(--newFont);
  }
}
