.cardEmpresas{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardEmpresa{
  width: 100%;
  border: 1px solid var(--grey);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardEmpresa img{
  height: 60px;
  width: auto;
  margin: 0 auto;
}

.infoCard{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.infoCard p{
  font: 1.2rem var(--fontGeneral);
  width: 50%;
}