.windowProcess{
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
}

.bottomPadding{
  padding-bottom: 260px;
}

.windowForm{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.windowFormComplete{
  width: 100%;
  display: flex;
}

.windowImagen{
  position: relative;
  display: none;
}

.windowContent{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--card);
  border-radius: 20px 20px 20px 20px;
}

.windowResume{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  background: var(--card);
  box-shadow: 0 3px 20px rgba(0,0,0,.3), 0 0px 30px rgba(0,0,0,.05);

  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 98;
}

.windowTicket{
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px dashed var(--grayA);
  border-radius: 10px;
  padding: 10px;
}

.windowTicketNone{
  display: none;
}

  .windowTicket div{
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    font: 1.2rem var(--fontGeneral);
  }

    .windowTicket div span:first-child{
      font-weight: 600;
    }

    .windowTicket div span:nth-child(2){
      text-align: right;
    }

.calculator{
  padding: 0px 0px 15px 0px;
  border-bottom: 1px solid var(--grayA);
}

.lineUp{
  padding-top: 5px;
  border-top: 1px solid var(--grayA);
}

.shortInfo{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shortInfo p{
  font: 1.6rem var(--fontGeneral);
  font-weight: 600;
  padding: 5px 10px;
  background: var(--primary-cl);
  border: 1px solid var(--secondary);
  border-radius: 10px;
  color: var(--secondary);
}
.shortInfo p span{
  font: 1.2rem var(--fontGeneral);
  display: block;
}

.cardShortTitle{
  display: none;
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
  color: var(--grey);
}

.btnResume{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: transparent;
  border: 1px solid var(--secondary);
  border-radius: 8px;
  color: var(--secondary);
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
}

  .btnResume:focus{
    border: 1px solid var(--secondary) !important;
    outline: none !important;
    box-shadow: none !important;
  }

.arrowUp{
  stroke: var(--secondary);
  width: 15px;
  margin-top: 2px;
}

.arrowDown{
  stroke: var(--secondary);
  width: 15px;
  transform: rotate(180deg);
  margin-top: 2px;
}

.btnsCase{
  display: flex;
  gap: 15px;
}

/* .blackResume{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.9;
  z-index: 99;
} */

.DatosPromo span{
  text-align: right;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width:  992px) and (max-width: 1200px) {

  .resumePlain{
    width: 40% !important;
  }

  .windowFormRowX{
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .windowFormRow{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .windowFormRowX .windowImagen,
  .windowFormRow .windowImagen{
    width: 100%;
    height: 150px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
    background-color: aqua !important;
  }

  .windowFormRowX .windowImagen svg,
  .windowFormRow .windowImagen svg{
    width: 100%;
  }

  .windowFormRowX .windowContent,
  .windowFormRow .windowContent{
    width: 100%;
    border-radius: 0px 0px 20px 20px;
  }
}

@media (min-width: 992px) {
  .windowProcess{
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    gap: 15px;
    padding-bottom: 0px;
  }

  .bottomPadding{
    padding-bottom: 0px;
  }

  .windowForm{
    width: 70%;
    display: flex;
  }

  .windowImagen{
    width: 30%;
    display: flex;
    border-radius: 20px 0 0 20px;
    background: rgb(0,24,180);
    background: -moz-linear-gradient(0deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
    background: linear-gradient(0deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0018b4",endColorstr="#035af4",GradientType=1);
    overflow: hidden;
  }

  .windowImagen svg{
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .ImgContent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    z-index: 1;
  }

  .ImgContent img{
    width: 100%;
  }

  .windowContent{
    width: 70%;
    padding: 30px;
    border-radius: 0px 20px 20px 0px;
  }

  .windowResume{
    width: 30%;
    position: relative;
    border-radius: 20px 20px 20px 20px;
    box-shadow: none;
    gap: 15px;
    z-index: 1;
  }

  .windowTicketNone{
    display: flex !important;
  }

  .cardShortTitle{
    display: flex;
  }

  .shortInfo p{
    font: 2rem var(--fontGeneral);
    font-weight: 600;
    padding: 10px;
  }
  .shortInfo p span{
    font: 1.2rem var(--fontGeneral);
    display: block;
  }

  .btnResume{
    display: none;
  }
}

@media (min-width: 1201px) {
  
  .windowFormRow{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width:  1201px) and (max-width: 1300px) {
  .windowFormRowX{
    width: 65%;
    display: flex;
    flex-direction: row;
  }

  .resumePlain{
    width: 35% !important;
  }
}




@media (min-width: 1300px) {
  .windowFormRowX{
    width: 70%;
    display: flex;
    flex-direction: row;
  }
}