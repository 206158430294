.counterDiv{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.counterDiv input{
  position: relative;
  width: 100%;
  height: 40px;
  padding: 6px 12px 6px 12px;
  background: var(--card);
  color: var(--textColor);
  border: solid 1px var(--grey-md);
  box-shadow: var(--shadow-card);
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  text-align: center;
}

.counterDiv input:hover {
  border: solid 1px var(--secondary) !important;
  box-shadow: var(--cardShadowLg);
}

.counterDiv input:focus{
  outline: none;
  border: solid 1px var(--secondary);
  box-shadow: none;
}

.counterDiv button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font: 1.8rem var(--fontGeneral), sans-serif;
  font-weight: 400;
  white-space: nowrap;
  color: var(--white);
  background: var(--primary);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.counterDiv button:hover{
  background: var(--primary-lg);
  color: var(--white);
}

.counterDiv button:first-child{
  border-radius: 10px 0px 0px 10px;
}

.counterDiv button:last-child{
  border-radius: 0px 10px 10px 0px;
}

.cardCounter{
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey-md);
}

.counterMin{
  max-width: 150px;
}
