.Fees {
    padding: 50px 0px;
    background: var(--white);
}

.FeesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.TextHea{
    text-align: center;
    margin-bottom: 10px;
}

.TitStyle{
    color: var(--gray);
    font: 2rem var(--newFont);
    font-weight: 400;
}

.TitStyle span{
    display: block;
    font: 3rem var(--newFont);
    font-weight: 900;
    color: var(--primary);
}

.ParStyle{
    margin: 20px 0px;
    font: 1.6rem var(--newFont);
    color: var(--textColor);
}

.FeesDiv {
    margin: 10px 15px;
    padding: 30px;
    max-width: 320px;
    border: 1px solid var(--gris-lg);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
}

.FeeName {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    font: 1.5rem var(--fontGeneral);
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.FeeText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.FeeValor {
    margin-bottom: 15px;
    color: var(--textColor);
    font: 5.5rem var(--fontGeneral);
    font-weight: 900;
}

.FeeValor span {
    font: 1.8rem var(--fontGeneral);
    font-weight: 400;
}

.FeeDesc {
    width: 100%;
    margin-bottom: 10px;
    color: var(--textColor);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    display: flex;
}

.FeeDesc span {
    font-weight: 600;
    color: var(--textColor);
}

.FeeDesc img{
    height: 20px;
    width: 20px;
    padding: 5px;
    filter: invert(1);
    margin-right: 5px;
}

.FeeCond {
    width: 100%;
    margin-top: 10px;
    color: #fff;
    font: 1rem var(--fontGeneral);
    text-align: center;
}

.FeeButton{
    margin-top: 25px;
}

.FeeButton button{
    width: 100%;
    background: transparent;
    color: var(--primary);
    border-radius: 8px;
    border: 1.5px solid var(--primary);
    padding: 12px 15px;
    font: 1.8rem var(--fontGeneral);
    font-weight: 500;
}

.FeeButton button:hover, 
.FeeButton button:focus{
    background: var(--primary);
    color: var(--blanco);
    border: 1.5px solid var(--primary);

}

.FeeButton button:focus{
    outline: none !important;
}

.SpanTyc{
    font: 1.2rem var(--fontGeneral); 
}

@media (min-width: 992px) {
    .Fees {
        padding: 90px 0px;
    }

    .FeesContainer {
        flex-wrap: nowrap;
        padding: 0 25px;
    }

    .TextHea{
        text-align: center;
        margin-bottom: 20px;
    }

    .TitStyle{
        font: 2.2em var(--fontGeneral);
        font-weight: 500;
        text-align: left;
    }

    .TitStyle span{
        font: 4rem var(--fontGeneral);
        font-weight: 900;
    }

    .ParStyle{
        font: 1.8rem var(--fontGeneral);
        text-align: left;
    }

    .FeesDiv {
        width: calc(50% - 5px);
    }

}