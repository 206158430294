:root {
  --primary: #0018b4;
  --secondary: #035af4;
  --black: #141414;
  --blanco: #ffffff;
  --primary-lg: #035af4;
  --primary-cl:#e8f7ff;
  --second-lg: #75dbb9;
  --red: #d63830;
  --red-lg: #fdeeee;
  --green: #1bb63f;
  --green-lg: #75dbb9;
  --green-dk: #005438;
  
  --grayA: #6A7180;
  --grey: #929bac;
  --grey-md: #b4b4b4;
  --grey-lg: #ececec;
  --yellow: #ffd500;
  --fontGeneral: "Inter", sans-serif;
  --fontHeader: "Barlow", sans-serif;
  --newFont: "Montserrat", sans-serif;
  --background: #eff0f2;
  --contrast: #12151A;
  --border: #DFE1E6;
  --icon:#9ca0aa;
  --white: #FFFFFF;
  --shadow: ;
  --activeli: #035af4;
  --unactiveli: #6A7180;
  --textColor: #6A7180;
  --card: #FFFFFF;
  --shadow-card: 0px 2px 3px rgba(106, 113, 128, 0.1);
  --cardShadowLg: 0px 1px 10px rgba(59, 130, 246, 0.4);
  --cardShadowWt: 0px 1px 10px rgba(255, 255, 255, 0.4);
  --ShadowRed: 0px 1px 10px rgba(246, 59, 96, 0.4);
  --logoColor: #141414;

  --label: #929bac;

  --grey1: #929bac;
  --grey2: #ececec;
  --headColor: #2A2E38;

  --card2: #ededed;
}

[data-theme='dark'] {
  --primary: rgb(0, 123, 255);
  --secondary: #2f90ff;
  --black: #141414;
  --primary-lg: #035af4;
  --primary-cl:#e8f7ff;
  --second-lg: #75dbb9;
  --red: #d63830;
  --red-lg: #fdeeee;
  --green: #1bb63f;
  --green-lg: #75dbb9;
  
  --grayA: #DFE1E6;
  --grey: #929bac;
  --gris-md: #b4b4b4;
  --grey-lg: #ececec;
  --yellow: #ffec00;
  --fontGeneral: 'Inter', sans-serif;
  --fontHeader: "Barlow", sans-serif;
  --newFont: "Montserrat", sans-serif;
  --background: #070708;
  --constrast: #F7F8FA;
  --border: #2A2E38;
  --icon:#6A7180;
  --white: #FFFFFF;
  --activeli: #FFFFFF;
  --unactiveli: #A1A7B3;
  --textColor: #FFFFFF;
  --card: #26282d;
  --shadow-card: 0px 2px 3px rgba(0, 0, 0, 0.2);
  --cardShadowLg: 0px 1px 10px rgba(59, 130, 246, 0.4);
  --ShadowRed: 0px 1px 10px rgba(246, 59, 96, 0.4);
  --logoColor: #FFFFFF;
  --label: #b9b9b9;

  --grey1: #929bac;
  --grey2: #303030;
  --headColor: #6fb2ff;

  --card2: #40434a;
}
.colorRed{color: var(--red);}
.greenStroke svg{stroke: #1bb63f;}

.buttonIcon{
  position: relative;
  height: 24px;
  width: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.icon{
  width: 20px;
  height: auto;
  stroke: var(--icon);
}

.flexDiv{
  display: flex;
  justify-content: space-between;
}

.smallText{
  font: 1rem var(--fontGeneral);
  font-weight: 400;
}

.SideApp{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 90px;
}

.contentApp{
  width: 100%;
  min-height: calc(100vh - 60px);
}

.noSee{
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .SideApp{
   display: none;
  }
}