.paginacion {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.pagNext,
.pagPrev {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey);

  padding: 5px;
}
.pagPrev {
  border-radius: 5px 0 0 5px;
}
.pagNext {
  border-radius: 0 5px 5px 0px;
}
.pagPrev svg,
.pagNext svg {
  stroke: var(--primary);
}

.pagNumber {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-lg);
  color: var(--grey);
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
}

.pagNumber:hover {
  background-color: #e8f7ff;
  color: var(--secondary);
}

.pagActive {
  background-color: #e8f7ff;
  color: var(--secondary);
  border-top: 1px solid var(--secondary) !important;
  border-left: 1px solid var(--secondary) !important;
  border-right: 1px solid var(--secondary) !important;
  border-bottom: 1px solid var(--secondary) !important;
}

.pagDisabled {
  background: var(--grey-lg);
  color: var(--grey);
  pointer-events: none;
}

.pagDisabled svg {
  stroke: var(--grey);
}

.pagBlock {
  display: none;
  pointer-events: none;
}

.TableHeader {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .TableFooter {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
