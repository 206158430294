.drop_zone {
  position: relative;
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  border: dashed 1px var(--grey-md);
  background: var(--card);
  font: 1.2rem var(--fontGeneral);
  text-align: center;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.drop_zone_active {
  border-color: var(--secondary) !important;
  background-color: var(--primary-cl);
}

.drop_zone:hover {
  border: dashed 1px var(--secondary);
  box-shadow: var(--cardShadowLg);
}

.drop_zone p {
  margin: 2px 0;
  color: #c7c7c7;
}

.drop_zone_active p {
  color: var(--secondary) !important;
}

.drop_zone_active svg {
  stroke: var(--secondary) !important;
}

.drop_zone p:first-child {
  color: rgba(0, 0, 0, 0.65);
}
.drop_zone_list {
  width: 100%;
  padding: 0 3px;
  box-sizing: border-box;
}
.drop_zone_list div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  font-size: 14px;
  padding: 0 3px;
  transition: all 0.1s ease;
  color: #3f4447;
}
.drop_zone_list div:hover {
  background: #e0f5ff;
}
.drop_zone_list div:hover a {
  display: block;
}
.drop_zone_list div p {
  margin: 5px;
}
.drop_zone_list div a {
  text-decoration: none;
  color: #3f4447;
  display: none;
  font-size: 16px;
  cursor: pointer;
}

.fileClick {
  border: 1px solid var(--red);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fileIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}

.fileIcon svg {
  stroke: var(--icon);
}

.drop_hover:hover {
  border-color: var(--azul-lg);
}
.drop_out:hover {
  border-color: var(--gris);
}

.spanAlertDocument {
  position: absolute;
  bottom: -8px;
  padding: 2px 5px;
  border-radius: 3px;
  background: var(--red-lg);
  color: var(--red);
  font: 1.2rem var(--fontGeneral), sans-serif;
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dashedRed {
  background: #fdeeee;
  border: dashed 1px var(--red) !important;
}

.dashedRed p {
  color: var(--red) !important;
}

.dashedRed svg {
  stroke: var(--red);
}

