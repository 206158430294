.inputBig{
  position: relative;
  border-radius: 10px;
  padding: 0 10px;
  border: 1px solid var(--grey-md);
}

.redText input{
  color: var(--red)!important
}

.inputBig input{
  height: 50px;
  width: 100%;
  padding: 6px 0px 6px 0px;
  
  background: transparent;
  color: var(--textColor);
  border: none;
  font: 2.2rem var(--fontGeneral);
  font-weight: 600;
}

.inputBig input:focus{
  outline: none;
  border: none !important;
  box-shadow: none;
}

.inputBig label{
  position: absolute;
  top: -8px;
  left: 5px;
  color: var(--textColor);
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
  background: var(--card);
  padding: 0px 5px 0px 5px;
}

.inputBig svg{
  position: absolute;
  top: 12px;
  right: 10px;
  height: 26px;
  width: 26px;
}