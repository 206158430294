.Selec {
  position: relative;
  padding: 0px;
  margin: 0 !important;
  width: 100%;
}

.Select{
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
}

.icoCoin{
  width: 20px;
}

.Selec_select {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin: 0 !important;
  border-radius: 10px;
  background: var(--card);
  color: var(--textColor);
  border: solid 1px var(--grey-md);
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
}

.redWarn {
  border: solid 1px #d63830 !important;
}

.Selec_select:hover {
  border: solid 1px var(--secondary);
  box-shadow: var(--cardShadowLg);
}

.Selec_Open {
  border-radius: 10px 10px 0px 0px !important;
}

.Selec_blue {
  border: solid 1px var(--secondary);
}

.Label_in {
  position: absolute;
  top: 8px;
  left: 5px;
  margin-left: 10px;
  color: var(--grey-md);
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  pointer-events: none;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.2s ease-in-out, background 0s ease-in-out;
  transition: all 0.2s ease-in-out, background 0s ease-in-out;
  z-index: 2;
}

.Label_out {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -28px, 0);
  transform: translate3d(0, -28px, 0);
  background: var(--card);
  color: var(--secondary);
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}

.Selec_opciones {
  position: absolute;
  left: 0px;
  top: 39px;
  width: 100%;
  height: auto;
  max-height: 160px;
  margin: 0 !important;
  border-radius: 0px 0px 10px 10px;
  background-color: var(--card);
  border-top: solid 1px var(--grey);
  border-left: solid 1px var(--secondary);
  border-right: solid 1px var(--secondary);
  border-bottom: solid 1px var(--secondary);
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 999;
}

.Selec_opciones::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
} 

.Selec_opciones::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(to left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.Selec_opciones::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.Selec_opciones::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

.Selec_opciones::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #22ADD4, #1E98BA);
}

.Select_opcion {
  padding: 10px 12px;
  margin: 0;
  margin: 0 !important;
  border-bottom: solid 1px var(--grey-md);
  font: 1.5rem var(--fontGeneral);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--textColor);
  display: flex;
  align-items: center;
  gap: 15px
}

.select_bank{
  display: flex;
  align-items: center;
  gap: 10px;
}

.Select_opcion img,
.Select_opcion svg{
  height: 30px;
  width: 30px;
  border-radius: 8px;
}

.Select_opcion:last-child {
  border-bottom: none;
}

.Select_opcion:hover {
  background-color: var(--secondary);
  color: var(--white);
}

.arrowSelect {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  opacity: 0.3;
  stroke: var(--textColor);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.arrowRotate {
  transform: rotate(180deg);
}

.selectButton {
  padding: 5px 12px;
}

.selectButton p {
  font: 1.5rem var(--fontSt), sans-serif;
  text-align: center;
  margin-top: 5px;
}

.doubleSelec {
  position: relative;
  width: 100%;
  display: flex;
}

.doubleSelec label{
  position: absolute;
  z-index: 2;
}

.doubleSelec .Selec {
  width: 50%;
}

.doubleSelec .Selec:first-child .Selec_select  {
  border-radius: 10px 0px 0px 10px;
}

.doubleSelec .Selec:last-child .Selec_select  {
  border-radius: 0px 10px 10px 0px;
}

.doubleSelec .Selec:first-child .Selec_Open {
  border-radius: 10px 0px 0px 0px !important;
}

.doubleSelec .Selec:last-child .Selec_Open {
  border-radius: 0px 10px 0px 0px !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {

}
