table {
  border-collapse: separate !important;
  border-spacing: 0;
  width: 100%;
}

.tablaGeneralDiv{
  display: none;
}

.tablaGeneral {
  border: solid var(--grey2) 1px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font: 1.4rem var(--fontGeneral);
}

.tablaGeneral tr{
  cursor: pointer;
}

.tablaGeneral tr td{
  color: var(--textColor);
}

.tablaGeneral tr:hover td{
  color: var(--primary) !important;
}

.tablaGeneral tr:hover {
  background: var(--primary-cl);    
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.tablaGeneral td, .tablaGeneral th {
  border-top: 1px solid var(--grey2);
  padding: 10px;
}
.tablaGeneral th {
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
  background-color: var(--grey2);
  color: var(--grey1);
  border-top: none;
  text-align: left;
}
.tablaGeneral td:first-child, .tablaGeneral th:first-child {
  border-left: none;
}
.tablaGeneral th:first-child {
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
}
.tablaGeneral th:last-child {
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
}
.tablaGeneral th:only-child{
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.tablaGeneral tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 6px;
  -webkit-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}
.tablaGeneral tr:last-child td:last-child {
  -moz-border-radius: 0 0 6px 0;
  -webkit-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
} 

.tdRight{
  text-align: right !important;
}

.tdCenter{
  text-align: center !important;
}

.tdIcon{
  width: 50px;
}

.tdImg img{
  width: 45px;
  border-radius: 10px;
}

.tablaGeneral a{
  color: var(--primary);
}

.TableHead {
  display: flex;
  justify-content: flex-end;
}

.filtrosHeader{
  display: none;
}

.cardsTable{
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.cardTable{
  position: relative;
  width: 100%;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 14px;
  padding: 25px 15px 15px 15px;
  border-radius: 15px;
  font: 1.2rem var(--fontGeneral);
  border: 1px solid #ccc;
}

.cardTable .infoDiv{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.imgInfo{
  position: absolute;
  top: -15px;
  right: 10px;
  
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  width: 40px;
  height: 30px;
  background: var(--card);
}

.imgInfo img{
  height: auto;
  width: 100%;
  border-radius: 100%;
}

.cardTable h3{
  position: absolute;
  top: -10px;
  left: 8px;
  color: var(--white);
  font: 1.2rem var(--fontGeneral);
  padding: 4px 10px !important;
  background: var(--primary);
  font-weight: 700;
  border-radius: 10px;
  z-index: 2;
}

.revisionsTop{
  background: var(--card);
  padding: 0 10px;
  position: absolute;
  top: -11px;
  right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.blueLabel{
  background: var(--primary);
}

.redLabel{
  background: var(--red) !important;
}

.cardTable p{
  font: 1.2rem var(--fontGeneral);
  display: block;
  color: var(--textColor);
}

.cardTable p label{
  font: 1rem var(--fontGeneral);
  font-weight: 600;
  color: var(--grey);
  display: block;
}

.TableFooter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.TableFooterNormal {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}



@media (min-width: 576px) {
  .cardTable{
    width: calc(50% - 13px);
    min-width: inherit;
  }
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

  .tablaGeneralDiv{
    display: flex;
  }
  
  .cardsTable{
    display: none; 
  }

  
  .filtrosHeader{
    display: flex;
    gap: 10px;
  }
}

@media (min-width: 1200px) {
}


