.paymentData{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.paymentData p{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font: 1.4rem var(--fontGeneral);
}

.paymentData p span:first-child{
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
}

.btnsLarge{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 1.4rem var(--fontGeneral);
  color: var(--primary-lg);
}

.btnsLargeTitle{
  width: 100%;
  padding: 10px;
  text-align: center;
  background: var(--textColor);
  border: 1px solid var(--textColor);
  border-bottom: none;
  color: var(--white);
  border-radius: 10px 10px 0px 0px;
  font: 1.4rem var(--fontGeneral);
}

.btnsLarge div img{
  width: 35px;
  border-radius: 10px;
}

.btnsLarge div{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 0px solid var(--textColor);
  cursor: pointer;
}

.btnsLarge div:first-child{
  border-top: none !important;
}

.btnsLarge div:last-child{
  border-bottom: 1px solid var(--textColor);
  border-radius: 0px 0px 10px 10px;
}

.btnsLarge div span{
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.btnDivLg, .btnsLarge div:hover{
  font: 1.4rem var(--fontGeneral);
  background-color: var(--primary-cl);
  border: 1px solid transparent !important;
  color: var(--primary-lg);
}

.btnDivLgNone{
  border: 1px solid var(--textColor);
  font: 1.4rem var(--fontGeneral);
  color: var(--textColor);
}




