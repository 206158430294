.contGeneral {
  width: 100%;
  min-height: 65vh;
  margin: 0 auto;
  padding: 60px 0px 0px 0px;
}

.contApp{
  max-width: 1200px;
  padding: 0px 15px 30px 15px;
  margin: 0 auto; 
}

.MainTitle {
  margin: 30px 0px 20px 0px;
  color: var(--primary);
  font: 2.8rem var(--fontHeader);
  font-weight: 700;
}

.SubTitle {
  margin: 40px 0px 10px 0px;
  font: 1.6rem var(--fontGeneral);
  font-weight: 700;
}

.TextGeneral {
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  text-align: justify;
}

.ulGeneral {
  padding: 0 0 0 20px;
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  text-align: justify;
}

.SectionTitle {
  width: 100%;
  margin: 0px 0px 15px 0px;
  color: var(--primary);
  font: 2.8rem var(--fontGeneral);
  font-weight: 700;
}

.SectionText {
  width: 100%;
  padding: 0 15px;
  margin: 0px 0px 30px 0px;
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
}

.TxtCenter {
  text-align: center;
}

.divStars{
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.DivStar img{
  width: 16px;
  margin: 0 1px;
  filter: invert(80%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

/*--CURRENCY CONVERTER--*/

.bannerInicio{
  background: var(--primary);
  background: -moz-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, var(--primary)),
    color-stop(100%, var(--primary-lg))
  );
  background: -webkit-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -o-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -ms-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#035af4', GradientType=0 );
}

.contBanner{
  position: relative;
  padding: 0px 0px 40px 0px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
}

.gallery{
  position: relative;
  height: 100%;
  width: 100%;
}

.gallerySlide{
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.gallerySlide img{
  height: 100%;
  width: 200%;
  margin-left: -12%;
  object-fit: contain;
}

.custom-dot-list-style{
  padding: 0px 0px 0px 0px;
  justify-content: center;
}

.custom-dot-list-style li button{
  background-color: transparent;
  border: 1px solid var(--blanco);
}

.custom-dot-list-style react-multi-carousel-dot button{
  background-color: transparent;
  border: 1px solid var(--blanco);
}

.custom-dot-list-style .react-multi-carousel-dot--active button{
  background: var(--yellow) !important;
  border: 1px solid var(--yellow) !important;
}

.contCurrency{
  max-width: 800px;
  padding: 15px 15px 15px 15px;
}

.calculatorDiv{
  width: 100%;
  padding: 40px 5px;
  background: var(--greyA);
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.h1Banner{
  text-align: center;
  color: var(--primary);
  font: 3rem var(--newFont);
  font-weight: 900;
}

.pBanner{
  margin-top: 20px;
  max-width: 400px;
  text-align: center;
  color: var(--black);
  font: 1.6rem var(--newFont);
  font-weight: 400;
}

/* .containerCurrency{
  width: 100%;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: var(--blanco);
  z-index: 3;
} */

/*CORE VALUES*/

.CoreValues {
  margin: 25px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.CoreValue {
  width: 100%;
  margin-bottom: 15px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--card);
  border: 1px solid var(--grey);
  border-radius: 8px;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Core50 {
  width: 100% !important;
}

.CoreImg {
  width: 100px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreImg img {
  height: 60px;
  filter: invert(0.9);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.CoreText {
  width: 100%;
}

.CoreText h3 {
  margin: 0 0 10px 0;
  color: var(--azul-st);
  font: 2.5rem var(--fontGeneral);
  font-weight: 700;
}

.CoreText p {
  color: var(--negro);
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
}

.CoreText p span {
  font-weight: 700;
}

.CoreValue:hover {
  background: var(--azul-st);
}

.CoreValue:hover .CoreImg img {
  filter: invert(0);
}

.CoreValue:hover p {
  color: #fff;
}

.CoreValue:hover h3 {
  color: var(--yellow);
}

/*INPUTS ACORDION*/

.Acordion {
  position: relative;
  margin-bottom: 15px;
  font: 1.6rem var(--fontGeneral);
  font-weight: 400;
  border-radius: 10px;
  background: var(--card);
  overflow: hidden;
}

.Acordion input {
  position: absolute;
  display: none;
}

.Acordion label {
  display: block;
  padding: 20px 0 20px 20px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid var(--gris-lg);
  font-weight: 500;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Acordion:hover label {
  color: var(--primary-lg);
  background: var(--primary-cl);
  border: 1px solid var(--primary-lg);
}

.AcordionExpand {
  height: 0;
  overflow: hidden;
}

.Acordion input:checked ~ label {
  border-radius: 10px 10px 0px 0px;
  color: var(--primary-lg);
  background: var(--primary-cl);
  border: 1px solid var(--primary-lg);
}

.Acordion input:checked ~ .AcordionExpand {
  height: auto;
  padding: 20px;
  border-left: 1px solid var(--primary-lg);
  border-bottom: 1px solid var(--primary-lg);
  border-right: 1px solid var(--primary-lg);
  border-radius: 0px 0px 10px 10px;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .contBanner{
    position: relative;
    padding: 20px 0px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: inherit;
    justify-content: right;
  }

  .gallery{
    position: absolute;
    top: 0;
    left: 0;
  }

  .gallerySlide{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .gallerySlide img{
    height: 100%;
    width: 100%;
    margin-left: -8%;
  }

  .custom-dot-list-style{
    padding: 0px 0px 50px 20px;
    justify-content: left;
  }

  .contCurrency{
    padding: 20px 20px 20px 0px;
    z-index: 3;
  }

  .containerCurrency{
    width: 100%;
    max-width: 350px;
  }

  /*CORE VALUES*/

  .CoreValue {
    width: calc(33.3% - 20px);
    margin: 10px;
  }

  .Core50 {
    width: calc(50% - 20px) !important;
  }

}

@media (min-width: 992px) {
  .contGeneral{
    padding: 90px 0px 0px 0px;
  }

  .contApp {
    padding: 0px 15px 80px 15px;
  }

  .gallerySlide img{
    margin-left: -5%;
  }

  .custom-dot-list-style{
    padding: 0px 0px 35px 30px;
    justify-content: left;
  }

  .contCurrency{
    padding: 20px 60px 20px 0px;
  }

  .TextHea{
    text-align: left;
    margin-bottom: 20px;
  }

  .ParStyle{
    max-width: 320px;
    margin-right: 50px;
  }

  /*CORE VALUES*/
  .CoreValues {
    margin: 35px auto 0 auto;
  }

  .CoreValue {
    padding: 30px 20px;
  }

  .CoreImg {
    width: 100px;
    margin-right: 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .CoreImg img {
    height: 80px;
  }

  .CoreText h3 {
    margin: 0 0 10px 0;
    font: 2rem var(--fontGeneral);
    font-weight: 700;
  }

  .CoreText p {
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
  }

  .MainTitle {
    margin-top: 50px;
    font: 3.5rem var(--fontGeneral);
    font-weight: 700;
  }

  .SectionTitle {
    font: 3.5rem var(--fontGeneral);
    font-weight: 700;
  }

  .Acordion {
    background: var(--card);
    position: relative;
    margin-bottom: 15px;
    font: 1.8em var(--fontGeneral);
    font-weight: 400;
  }

  .calculatorDiv{
    width: 100%;
    padding: 40px 5px;
    background: var(--greyA);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .h1Banner{
    text-align: left;
    font: 4rem var(--newFont);
    font-weight: 900;
  }
  
  .pBanner{
    margin-top: 20px;
    text-align: left;
    font: 1.8rem var(--newFont);
    font-weight: 400;
  }
  
}

@media (min-width: 1200px) {
  .contBanner{
    padding: 60px 0px;
  }

  .gallerySlide img{
    margin-left: -7%;
  }
  
  .custom-dot-list-style{
    padding: 0px 0px 70px 20px;
    justify-content: left;
  }

  .contCurrency{
    padding: 20px 50px 20px 0px;
  }

  .MainTitle {
    font: 4rem var(--fontGeneral);
    font-weight: 700;
  }
  
  .TextGeneral {
    font: 1.8rem var(--fontGeneral);
  }

  .SubTitle {
    font: 1.8rem var(--fontGeneral);
    font-weight: 700;
  }

  .ulGeneral {
    font: 1.8rem var(--fontGeneral);
    font-weight: 400;
  }

  .CoreText h3 {
    font: 2.8rem var(--fontGeneral);
    font-weight: 700;
  }

  .CoreText p {
    font: 1.8rem var(--fontGeneral);
    font-weight: 400;
  }
  
}

@media (min-width: 1370px) {
  
}
