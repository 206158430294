.navigate{
    position: fixed;
    top: 0px;
    /*display: flex;
    justify-content: center; */
    width: 100%;
    height: 60px;
    border-bottom: 1px solid var(--border);
    background: var(--card);
    z-index: 9;
}

.navBox{
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.navLeft{
    display: none;
}

.navLeft a{
    color: var(--textColor);
}

.navRigth{
    display: flex;
    align-items: center;
    gap: 24px;
    
}

.navRigth a{
    font: 1.4rem var(--fontGeneral);
}

.logoApp{
    height: 35px;
    fill: var(--logoColor);
}

.displayBtns{
    display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .navigate{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 92px;
        border-bottom: 1px solid var(--border);
        background: var(--card);
    }

    .navBox{
        padding: 0px 32px;
    }

    .navLeft{
        display: flex;
        gap: 30px;
        font: 1.4rem var(--fontGeneral);
    }

    .logoApp{
        height: 50px;
    }
    
}

@media (min-width: 1200px) {
}
