.sideApp{
  display: inherit;
}

.sideBarOpen{
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(0%);
  width: 100%;
  height: 100vh;
  z-index: 99;
  opacity: 1;
  display: flex;
}

.sideBarClose{
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-100%);
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 99;
  opacity: 1;
}

.backSide{
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.8;
  z-index: 1;
}

.sideMenu{
  display: flex;
  flex-direction: column;
  background: var(--card);
  
  animation-name: example;
  animation-duration: 0.25s;
 
  width: 296px;
  height: 100%;
  z-index: 2;
}

.sideMenuHide{
  display: flex;
  flex-direction: column;
  background: var(--card);
  transform: translate(0px);
  animation-name: example2;
  animation-duration: 0.3s;
 
  width: 296px;
  height: 100%;
  z-index: 2;
}

@keyframes example {
  from {transform: translate(-296px);}
  to {transform: translate(0px);}
}

@keyframes example2 {
  from {transform: translate(0px);}
  to {transform: translate(-296px);}
}

.sideComplete, .sideIncompleted {
  display: flex !important;
}

.sideListMenu{
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sideListMenu::-webkit-scrollbar {
  width: 5px;
  background-color: linear-gradient(to left, #fff, #e4e4e4);
} 

.sideListMenu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #aaa;
}

.sideListMenu::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.sideListMenu::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
  border: none;
}

.sideListMenu::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.1);
}

.sideBar{
  position: fixed;
  left: 0;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-right: 1px solid var(--border);
  z-index: 6;
}

  .sideMin{
    width: 80px !important;
    overflow: hidden;
  }

    .sideMin:hover{
      width: 296px !important;
    }

.sideIconBar{
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 60px;
  padding: 10px 0px;

  display: none;
  flex-direction: column;
  align-items: center;

  background: var(--card);
  border-right: 1px solid var(--border);
  z-index: 100;
}

.logoIcon{
  height: 35px;
  fill: var(--logoColor);
}

.levelIcon,
.itemsIcon button{
  position: relative;
}

.levelIcon:hover span,
.itemsIcon button:hover span{
  opacity: 1;
  display: flex;
}

.levelIcon span,
.itemsIcon button span{
  position: absolute;
  top: 0;
  left: 35px;
  white-space:nowrap;
  color: var(--white);
  padding: 5px 8px;
  border-radius: 8px;
  background-color: var(--secondary);
  font: 1.1rem var(--fontGeneral);
  opacity: 0;
  display: none;
}

.items{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid var(--border);
}

.itemsIcon{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid var(--border);
}

.levelZ{
  display: flex;
  flex-direction: column;
}

.normalMenu{
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;
  gap: 24px;
  width: 296px;
  font: 1.4rem var(--fontGeneral);
  font-weight: 500;
  line-height: 20px;
}

.normalMenu a{
  color: var(--unactiveli);
  display: block;
}

.levelA{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  
  height: 24px;

  font: 1.4rem var(--fontGeneral);
  font-weight: 500;
  line-height: 20px;
  color: var(--unactiveli);
}

.levelB{
    padding: 0px 0 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 0px;
    font: 1.4rem var(--fontGeneral);
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.levelB a,
.levelA a{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  color: var(--unactiveli);
}

.liActivo{
  color: var(--activeli) !important;
  font-weight: 600;
}

.levelBAct{
  padding: 16px 0 0 24px;
  height: auto !important; 
}

.items a svg, .itemsIcon a svg{
  stroke: var(--icon);
}

.items a:hover, 
.itemsIcon a:hover,
.liSideItem:hover{
  color: var(--secondary) !important;
}

.liActivo svg,
.items a:hover svg,
.itemsIcon a:hover svg,
.liSideItem:hover svg{
  stroke: var(--secondary) !important;
}

.liSideItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    background: transparent;
    height: 24px;

    font: 1.4rem var(--fontGeneral);
    font-weight: 400;
    line-height: 20px;
    color: var(--grayA);
}

.DropDownMenu{
    stroke: var(--icon);
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.DropDownMenuA{
  stroke: var(--activeli);
  transform: rotate(-180deg);
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mobileNone, .sideIconBarNone{
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .mobileNone{
    display: flex;
  }

  .sideApp{
    display: none;
  }

  .sideBar{
    top: 92px;
    left: 0;
    height: calc(100vh - 92px);
    width: 296px;
    padding: 0px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    background: var(--card);
    border-right: 1px solid var(--border);
  }

  .sideBarClose{
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0);
    animation-name: none;
    width: 296px;
    height: 100vh;
    z-index: 99;
    opacity: 1;
  }

  .sideBarOpen{
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0);
    width: 296px;
    height: 100vh;
    z-index: 99;
    opacity: 1;
  }

  .sideMenu,
  .sideMenuHide{
    display: flex;
    flex-direction: column;
    background: var(--card);
    border-right: 1px solid var(--border);
    animation-name: none;
    width: 296px;
    height: 100%;
    z-index: 2;
  }

  .sideComplete{
    display: flex !important;
  }
  .sideIncompleted{
    display: none !important;
  }

  .backSide{
    display: none;
  }

  .sideIconBar{
    display: flex;
  }
  .sideIconBarNone{
    display: none;
  }

}

@media (min-width: 1200px) {

}
