.Footer {
  width: 100%;
  background-color: var(--primary);
}

.info-footer {
  padding: 20px 0 20px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.info-footer a {
  height: 30px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--blanco);
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
}

.info-footer a img {
  height: 20px;
  width: 20px;
  filter: invert(1) brightness(10);
}

.icons-footer {
  display: none;
}

.linkPoliticas,
.linkPoliticas a, 
.linkPoliticas a:visited{
  color: #808080;
  font: 1.4rem var(--fontGeneral);
  cursor: pointer;
}

.copy-footer {
  width: 100%;
  padding: 15px 35px 15px 35px;
  background-color: #262626;
  color: var(--blanco);
  font: 1.3rem var(--fontGeneral);
  font-weight: 400;
  text-align: center;
}

.copy-footer span {
  color: #808080;
  font: 1.1rem var(--fontGeneral);
  font-weight: 300;
}

.footerAustrac{
  width: 100%;
  margin-bottom: 20px;
  filter: invert(1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerAustrac img{
  width: 100%;
  max-width: 250px;
}

.footerDash{
  display: none;
}

@media (min-width: 576px) {
  .Footer {
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .Footer {
    display: block;
  }

  .info-footer {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-footer a {
    height: 30px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blanco);
    font: 1.4rem var(--fontGeneral);
    font-weight: 400;
  }

  .info-footer a img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    filter: invert(1) brightness(10);
  }

  .icons-footer {
    padding: 20px 0px 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons-footer div {
    height: 30px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--blanco);
    color: var(--yellow);
    font: 1.6rem var(--fontGeneral);
    font-weight: 600;
  }

  .icons-footer div:last-child {
    border-right: none;
  }

  .icons-footer div img {
    height: 40px;
    margin-right: 10px;
  }

  .linkPoliticas,
  .linkPoliticas a, 
  .linkPoliticas a:visited{
    color: #808080;
    font: 1.6rem var(--fontGeneral);
    cursor: pointer;
  }
  
  .copy-footer {
    padding: 30px 0px;
    background-color: #262626;
    color: var(--blanco);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    text-align: center;
  }

  .copy-footer span {
    color: #808080;
    font: 1.4rem var(--fontGeneral);
    font-weight: 300;
  }
}

@media (min-width: 992px) {
  .footerDash{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    text-align: center;
    font: 1.2rem var(--fontGeneral);
    font-weight: 400;
    color: var(--label);
  }
}

@media (min-width: 1200px) {
}
