.itemsCards{
  display: flex;
  gap: 10px;
}

.itemCard{
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--primary-cl);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 10px;
  flex: 1;
}

.itemCard svg{
  height: 30 px;
  width: 30px;
  stroke: var(--primary);
}

.itemCard div{
  display: flex;
  flex-direction: column;
}

.itemCard label{
  font: 1.1rem var(--fontGeneral);
  font-weight: 400;
}

.itemCard span{
  font: 1.6rem var(--fontGeneral);
  font-weight: 700;
}