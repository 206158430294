.pageForm{
  width: 100%;
  min-height: 100vh;
  background: var(--background);
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.boxForm{
  box-shadow: var(--shadow-card);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
}

.headerForm{
  padding: 30px;
  background: var(--primary);
}

.headerForm h1{
  font: 2.2rem var(--fontGeneral);
  font-weight: 900;
  color: var(--yellow);
}

.contentForm{
  padding: 30px;
  background: var(--card);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dataForm{
  display: flex;
  gap: 20px;
}

.dataForm form{
  max-width: 400px;
}

.resumeForm{
  width: 250px;
}

.stepForm{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonsForm{
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

/*PROGRESS*/
.formProgress ul{
  display: flex;
  gap: 40px;
  padding: 0;
}

.formProgress ul li{
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
}

.formProgress ul li div{
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  font: 1.2rem var(--fontGeneral);
  width: 30px;
  height: 30px;
  padding: 10px 10px;
  background: var(--primary-cl);
  border-radius: 5px;
}

.formProgress ul li div svg{
  stroke: var(--primary);
  height: 20px;
  width: 20px;
}

.checkStep div, .currentStep div{
  color: var(--primary) !important;
  background-color: var(--yellow) !important;
}

/*BIG BUTTON*/

.bigButtonSelect{
  position: relative;
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  border: solid 1px var(--grey-md);
}

.ErrorBigButton{
  border: solid 1px var(--red);
}

.ErrorBigButton h3{
  background: var(--red) !important;
}

.bigButtonSelect h3{
  padding: 8px;
  width: 100%;
  background: var(--secondary);
  color: var(--white);
  text-align: center;
  border-radius: 10px 10px 0 0;
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}

.bigButtons{
  display: flex;
}

.bigButton{
  width: 50%;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}

.bigButton:hover{
  background: var(--primary-cl);
  color: var(--secondary);
}

.bigButton span{
  font: 1.6rem var(--fontGeneral);
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}

.bigButton label{
  display: block;
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
}

.bigButton p{
  font: 1rem var(--fontGeneral);
  font-weight: 400;
}

.bigButton:first-child{
  border-right: solid 1px var(--grey-md);
  border-radius: 0 0 0 10px;
}

.bigButton:last-child{
  border-radius: 0 0 10px 0;
}

.bigButtonA span{
  color: var(--white);
}
.bigButtonA label{
  color: var(--yellow);
}

.bigButtonA p{
  color: var(--white);
}

.bigButtonA{
  background: var(--primary);
  color: var(--secondary);
}

.bigButtoOne{
  width: 100%;
}

.bigButtonUnabled{
  position: relative;
  padding: 20px 15px !important;
  background: #f7f7f7 !important;
  cursor: not-allowed !important;
  overflow: hidden;
}

.bigButtonUnabled:hover{
  background: #f7f7f7 !important;
  cursor: not-allowed !important;
  color: var(--textColor);
}

.bigButtonUnabled::before{
  content: 'No Disponible';
  position: absolute;
  padding: 5px 0px;
  right: -36%;
  top: 20px;
  width: 100%;
  background: #4d4d4d;
  color: #fff;
  transform: rotate(45deg);
  text-align: center;
  font: 1rem var(--fontSt);
}

.bigButtonUnabled:hover::before{
  background: var(--red);
}

.spnbigButton{
  position: absolute;
  bottom: -10px;
  left: 10px;
  padding: 2px 5px;
  border-radius: 3px;
  background: var(--red-lg);
  color: var(--red);
  font: 1.1rem var(--fontGeneral);
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.valueCard{
  width: 100%;
  padding: 20px;
  background: var(--grey-md);
  border-radius: 10px;
}

.valueCard span{
  font: 1.2rem var(--fontGeneral);
  display: block;
}

.valueCard label{
  font: 1.8rem var(--fontGeneral);
  font-weight: 600;
}

.valueCardB{
  background: var(--primary-cl);
  color: var(--secondary);
}

/*RECOMENDACIONES*/

.Recomendacion{
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  background: rgba(255, 236, 0, .15);
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
  cursor: pointer;
}

.Recomendacion svg{
  stroke: var(--black);
  width: 30px;
}

.Recomendacion span{
  font: 1.2rem var(--fontGeneral);
  font-weight: 700;
}

.Recomendacion p{
  margin-top: 5px;
}

.Recomendacion:hover{
  background: rgba(255, 236, 0, .6);
}

/*FLEXES*/
.infoForm{
  display: flex;
  flex-direction: column;
  gap: 20px;
}