.cardDoc{
  width: 100%;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: var(--shadow-card);
}

.cardDoc30{
	width: calc(100% / 3 - 14px);
}

.cardDocImg{
    position: relative;
    height: 120px;
    overflow: hidden;
}

/* .cardDocImg::before{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--black);
    mix-blend-mode: multiply;
    opacity: 0.1;
    z-index: 1;
} */

.cardDocImg:hover img{
    transform: scale(1.1);
    filter: grayscale(0%);
}

.cardDocImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer;
    filter: grayscale(100%);
}

.cardDocBottom{
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.cardDocBottom span{
    color: var(--grey1);
    font: 1.4rem var(--fontGeneral);
    font-weight: 600;
    display: block;
}

.cardDocBottom p{
    color: var(--grey1);
    font: 1.2rem var(--fontGeneral);
    font-weight: 400;
}
.cardDocBtn{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
