.backLand{
  background: var(--primary);
  background: -moz-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, var(--primary)),
    color-stop(100%, var(--primary-lg))
  );
  background: -webkit-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -o-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: -ms-linear-gradient(
    top,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--primary) 0%,
    var(--primary-lg) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#035af4', GradientType=0 );
}

.backLand{
  min-height: 70vh;
  padding: 50px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.backLand h1{
  color: var(--yellow);
  font: 3rem var(--newFont);
  text-align: center;
  font-weight: 900;
}

.MaxForm{
  max-width: 500px;
}

.divLand{
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #000942;
}

.divLand img{
  width: 100%;
  max-width: 400px;
}

.backLandp{
  max-width: 500px;
  text-align: center;
  color: var(--white);
  font: 1.4rem var(--newFont);
  font-weight: 400;
}