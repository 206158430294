.BoxGeneral{
  min-height: 100vh;
  background: var(--background);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.OptionBox{
  display: flex;
  flex-direction: column;

  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--secondary);
  background: var(--primary-cl);

  font: 1.2rem var(--fontGeneral);
  font-weight: 400;

  color: var(--secondary);
}

.modalBox{
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  padding: 0px 15px 0px 10px;

  background-color: rgba(0, 0, 0, 0.713);
  position: absolute;
  z-index: 5;

  display: flex;
  align-items: flex-end;
}

.modalBoxIn{
  width: 100%;
  background: var(--card);
  padding: 50px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px 20px 0px 0px;
}

.FormBox{
  width: 100%;
  margin-bottom: 60px;
  background: var(--card);
  padding: 30px 20px 30px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-radius: 20px;
}

.FormImage{
  display: none;
}

.FormOptions{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.OptionsBtn{
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--grey);
  padding: 15px;
  border-radius: 14px;
  cursor: pointer;
}

.OptionsBtn:hover{
  background: var(--secondary);
}

.OptionsBtn:hover p,
.OptionsBtn:hover h1{
  color: var(--white);
}

.OptionsBtn:hover svg{
  stroke: var(--white);
}

.OptionIcon{
  width: 25px;
}

.OptionIcon svg{
  width: 25px;
  stroke: var(--textColor);
}

.OptionsBtn h1{
  color: var(--primary);
  font: 1.8rem var(--fontGeneral);
  font-weight: 600;
  margin-bottom: 5px;
}

.OptionsBtn p{
  color: var(--textColor);
  font: 1.2rem var(--fontGeneral);
}

.InfoPoints{
  display: flex;
  flex-direction: column;
  font: 1rem var(--fontGeneral);
  font-weight: 400;
  color: var(--textColor);
}

.InfoPoints label{
  color: var(--secondary);
}

.labeled{
  text-decoration:line-through;
  color: var(--red) !important;
}

.DivLine{
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(164, 164, 164, 0.571);
}

.ProcessBtn{
  position: relative;
  display: flex;
  flex-direction: column;

  border: 1px solid var(--primary);
  border-radius: 10px 10px 10px 10px;
}

.btnSpan{
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 5px;
  border-radius: 3px;
  background: var(--red-lg);
  color: var(--red);
  font: 1.1rem var(--fontGeneral);
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ProcessBtnError{
  visibility: visible;
  border: 1px solid var(--red);
}

.ProcessBtnError .BtnCardHead{
  background: var(--red) !important;
}

.BtnCardHead{
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  border-radius: 8px 8px 0px 0px;
}

.BtnCardHead h3{
  color: var(--white);
  font: 1.3rem var(--fontGeneral);
  font-weight: 600;
}
.BtnCardHead svg{
  height: 20px;
  stroke: var(--white);
}

.BtnCardBtns{
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.BtnCardInfo{
  position: absolute;
}

.BtnCardBtnsA{
  display: flex;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.BtnCardOnly{
  width: 100% !important;
  background: transparent;
  padding: 15px 10px;
  font: 1.3rem var(--fontGeneral);
  font-weight: 600;
  background: var(--primary-cl) !important;
  color: var(--primary) !important;
}

.BtnCardBtnsA button{
  width: 50%;
  background: transparent;
  padding: 20px 10px;
  font: 1.3rem var(--fontGeneral);
  font-weight: 600;
  color: var(--textColor);
}

.BtnCardBtnsA button label{
  display: block;
  font: 1.2rem var(--fontGeneral);
}

.BtnCardA{
  background: var(--primary-cl) !important;
  color: var(--primary) !important;
}

.BtnUnabled{
  position: relative;
  cursor: not-allowed !important;
  overflow: hidden;
}

.BtnUnabled::before{
  content: 'No Disponible';
  position: absolute;
  padding: 5px 0px;
  right: -36%;
  top: 20px;
  width: 100%;
  background: var(--grey);
  color: var(--white);
  transform: rotate(45deg);
  text-align: center;
  font: 1rem var(--fontGeneral);
  font-weight: 600;
}

.BtnUnabled label,
.BtnUnabled span,
.BtnUnabled:hover label,
.BtnUnabled:hover span {color: var(--grey)}

.BtnUnabled:hover {background: var(--grey2) !important}
.BtnUnabled:hover::before{
  background: var(--red) !important;
}


.BtnCardBtns{
  display: none;
}
.BtnCardInfo{
  display: none;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.BtnCardInfoA{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  font: 1.2rem var(--fontGeneral);
  color: var(--textColor);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.BtnCardInfo p{
  width: 100%;
}

.BtnCardInfoA button{
  width: 100%;
  background: var(--primary-cl);
  padding: 10px;
  font: 1.3rem var(--fontGeneral);
  font-weight: 600;
  border-radius: 10px;
  color: var(--secondary);
}

.BtnCardInfoA span{
  display: block;
  font-weight: 600;
}

.FormResume{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: red;
  padding: 20px 15px;
  border-radius: 20px 20px 0 0;
  background: var(--card);
  box-shadow: 0 3px 20px rgba(0,0,0,.3), 0 0px 30px rgba(0,0,0,.05);

  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .FormProcess{
    display: flex;
  }

  .FormBox{
    width: 100%;
    margin-bottom: 60px;
    background: var(--card);
    padding: 30px 20px 30px 20px;
  
    gap: 20px;
  
    border-radius: 20px;
  }

  .FormBox{
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 0px;
  }

  .FormBox{
    padding: 30px 20px 30px 20px;
    border-radius: 0px;
  }


  .FormImage{
    display: flex;
    background: var(--primary);
    width: 250px;
    min-height: 400px;
  }

  .FormContent{
    width: calc(100% - 250px);
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }


  .FormOptions{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .OptionsBtn{
    width: 250px;
    padding: 20px;
    flex-direction: column;
  }

  .OptionIcon{
    width: 40px;
  }

  .OptionIcon svg{
    width: 40px;
  }

  .OptionsBtn h1{
    font: 2rem var(--fontGeneral);
    font-weight: 600;
    text-align: center;
  }

  .OptionsBtn p{
    text-align: center;
    font: 1.4rem var(--fontGeneral);
  }


  .FormResume{
    position:relative;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0px;
    border-radius: 20px 20px 0 0;
    background: var(--card);
    box-shadow: none;
  
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 5;
  }

}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {
  
}