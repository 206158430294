.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.loadingContainerW {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.pageLoader {
    height: 100vh !important;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid var(--primary);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.loaderDiv{
    position: relative;
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderBox{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.loaderBox::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: var(--background);
    opacity: .95;
}

.loader{
    position: relative;
}

.loaderLogo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 50px;
    fill: var(--logoColor);
}

.circle-load {
    width: 130px;
    animation: rotate-load 4s linear infinite;
}

.circle-load .circle {
    animation: loading-circle 2s linear infinite;
    stroke: var(--primary);
    fill: transparent;
    stroke-dasharray: 300;
    stroke-width: 3;
    stroke-linecap: square;
}

@keyframes rotate-load {
    from{ 
  transform: rotate(0deg); 
}
    to { 
  transform: rotate(360deg);
}
}
@keyframes loading-circle {
    from { 
  stroke-dashoffset: 0
}
    to { 
  stroke-dashoffset:-600
}
}

.spinnerDots{
    position: absolute;
    height: 40px;
    width:100%;
    top: 5px;
    left: 0px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-align:left;
    background: var(--card);
    z-index: 0;
}

.spinnerDots > div{
    background-color:#0065ff;
    border-radius:100%;
    display:inline-block;
    width:14px;
    height:14px;
    -webkit-animation:rk-bouncedelay 1.4s infinite ease-in-out both;
    animation:rk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinnerDots .dots1{
    -webkit-animation-delay:-0.32s;
    animation-delay:-0.32s;
}
.spinnerDots .dots2{
    -webkit-animation-delay:-0.16s;
    animation-delay:-0.16s;
}

@-webkit-keyframes rk-bouncedelay{
 0%, 80%, 100%{
    -webkit-transform:scale(0) 
    }
 40%{
    -webkit-transform:scale(1.0)
    }
}
 @keyframes rk-bouncedelay{
 0%, 80%, 100%{
    -webkit-transform:scale(0);
    transform:scale(0);
    }   
 40%{
    -webkit-transform:scale(1.0);
    transform:scale(1.0);
    }
}