.contError404 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    background: -moz-linear-gradient(
      top,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, var(--primary)),
      color-stop(100%, var(--secondary))
    );
    background: -webkit-linear-gradient(
      top,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    background: -o-linear-gradient(
      top,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    background: -ms-linear-gradient(
      top,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    background: linear-gradient(
      to bottom,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#035af4', GradientType=0 );
  }
  
  .Error404 {
    text-align: center;
    padding: 0 20px;
  }
  
  .Error404 h1 {
    margin: 0 0 10px 0;
    color: var(--yellow);
    font: 6rem var(--fontSt);
    font-weight: 700;
    line-height: 6rem;
  }
  
  .Error404 h3 {
    margin: 0;
    color: var(--yellow);
    font: 2rem var(--fontGeneral);
    font-weight: 700;
  }
  
  .Error404 a {
    padding: 12px 20px;
    background-color: #fff;
    color: var(--azul-lg);
    font: 1.4rem var(--fontGeneral);
    font-weight: 700;
    border-radius: 10px;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  
  .Error404 a:hover {
    background-color: var(--primary);
    color: #fff;
  }
  
  .Error404 p {
    color: #fff;
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    margin: 0 auto 50px auto;
    max-width: 300px;
  }
  
  @media (min-width: 768px) {
    .Error404 h1 {
      font: 8rem var(--fontGeneral);
      font-weight: 700;
    }
  
    .Error404 h3 {
      font: 3rem var(--fontGeneral);
      font-weight: 700;
    }
  
    .Error404 p {
      font: 1.8rem var(--fontGeneral);
      font-weight: 400;
      max-width: inherit;
    }
  
    .Error404 a {
      padding: 18px 25px;
      font: 1.6rem var(--fontGeneral);
      font-weight: 700;
    }
  }
  