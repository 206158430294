.checkBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.checkBox label {
  font: 1.2rem var(--fontGeneral);
  display: flex;
}

.checkBox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.checkBox input + label::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.checkBox input:hover + label::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid var(--secondary);
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.checkBox input[type="checkbox"]:checked + label::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content:'';
  width: 20px;
  height: 20px;
  background: var(--secondary);
  background-image:  url(../../img/icons/check.svg);
  background-position: 60% center;
  background-repeat: no-repeat;
  background-size: 70%;
  border: 2px solid var(--secondary);
  border-radius: 5px;
  cursor: pointer;
}

/*EN BOTONES*/
.ChecksBoxsBt{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.checkBoxBtn{
  width: 100%;
  position: relative;
}

.checkBoxBtn input[type="checkbox"] + label{
  width: 100%;
  font: 1.4rem var(--fontGeneral);
  height: 40px;
  padding: 6px 12px 6px 12px;
  border-radius: 10px;
  border: 1px solid var(--grey-md);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkBoxBtn input[type="checkbox"] {
  display: none;
}

.checkBoxBtn input[type="checkbox"]:checked + label {
  width: 100%;
  font: 1.4rem var(--fontGeneral);
  height: 40px;
  padding: 6px 12px 6px 12px;
  border-radius: 10px;
  background: var(--primary-cl);
  color: var(--secondary);
  display: flex;
  align-items: center;

  border: 1px solid var(--secondary);
  cursor: pointer;
}
