.singlePage{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.centerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.divCardPage{
  background: var(--card);
  padding: 30px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.divCardPage p{
  font: 1.4rem var(--fontGeneral);
  text-align: center;
}

.divCardPage span{
  font: 1.4rem var(--fontGeneral);
  text-align: center;
}

.divCardPage label{
  text-align: center;
}

.singlePageOut{
  font: 1.4rem var(--fontGeneral);
}

.welcomeDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  width: 100%;
  padding: 80px 15px 50px 15px;
  background: var(--background);
}

.welcomeTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 500px;
  background: var(--primary);
  border-radius: 20px;
  padding: 40px;
}

.welcomeTitle h1{
  text-align: center;
  font: 2.2rem var(--fontGeneral);
  font-weight: 600;
  color: var(--white);
}

.welcomeTitle h1 span{
  color: var(--yellow);
}

.welcomeTitle h3{
  font: 1.6rem var(--fontGeneral);
  background: var(--secondary);
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 400;
  color: var(--white);
}

.welcomeTitle p{
  color: var(--white);
  font: 1.4rem var(--fontGeneral);
  text-align: center;
}

.welcomeCards{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.welcomeCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 500px;

  background: var(--card);
  border-radius: 20px;

  padding: 20px;
}

.divWelcome{
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.welcomeCard SVG{
  width: 100%;
  fill: var(--secondary);
}

.welcomeCard p{
  font: 1.4rem var(--fontGeneral);
}

.svgWelcome{
  width: 100px;
}


