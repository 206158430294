.tabCheck{
  stroke: var(--green);
}
.tabThick{
  stroke: var(--grey);
}

.Registrada{
  color: var(--primary);
}
.Revision{
  color: var(--yellow);
}
.Proceso{
  color: var(--secondary);
}
.Procesada{
  color: var(--green);
}
.Cancelada{
  color: var(--red);
}

.marginR5{
  margin-right: 5px;
}