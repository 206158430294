@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'flags' ;
  src: url('./../fonts/flags-color-world.ttf');
}

html {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontGeneral);
  background: var(--background);
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

a,
a:hover,
a:visited {
  text-decoration: none !important;
  color: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p, h1, h2, h3 {
  padding: 0;
  margin: 0;
}

textarea{
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px var(--card) inset !important;
  -webkit-text-fill-color: var(--textColor) !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

::-moz-selection { /* Code for Firefox */
  color: var(--white);
  background: var(--secondary);
}

input::selection,
::selection {
  color: var(--white);
  background: var(--secondary);
}

input[type="date"]
{
  display: block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/*WHATSAPP*/

.whatsapp {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 45px;
  height: 45px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #00bb2d;
  z-index: 99;
}

.whatsapp svg {
  width: 25px;
  height: 25px;
  fill: var(--white)
}

/*VIDEOS*/

.video-responsive{
  height: 0;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  padding-bottom: 280px;
  padding-top: 30px;
  margin: 5px auto 5px auto;
  position: relative;
}

.video-responsive iframe, 
.video-responsive object, 
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pageOut{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageOut p{
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
  color: var(--grey);
}

.noticeWeb{
  min-height: 40px;
  text-align: center;
  font: 1.4rem var(--fontGeneral);
  background-color: var(--yellow);
  color: var(--primary);
  width: 100%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noticeWeb2{
  min-height: 40px;
  text-align: center;
  font: 1.4rem var(--fontGeneral);
  background-color: var(--yellow);
  color: var(--primary);
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 18px;
}

.noticeWeb b, .noticeWeb2 b{
  color: var(--primary);
}

@media (min-width: 768px) {
  /*WHATSAPP*/
  .whatsapp {
    right: 20px;
    bottom: 20px;
  }

  /*VIDEOS*/
  .video-responsive{
    padding-bottom: 300px;
  }

}

@media (min-width: 992px) {
  .video-responsive{
    margin: 24px auto 20px auto;
    padding-bottom: 500px;
  }

  .noticeWeb2{
    margin-bottom: 0px;
    border-radius: 0px;
  }
}
