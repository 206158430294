.cardSaludo{
  position: relative;
  width: 100%;
  border-radius: 20px;
  padding: 32px 30px;
  box-shadow: var(--shadow-card);

  display: flex;
  flex-direction: column;
  gap: 15px;

  color: var(--white);

  background: rgb(0,24,180);
  background: -moz-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0018b4",endColorstr="#035af4",GradientType=1);
}

.cardSaludo span{
  font: 1.2rem var(--fontGeneral);
}

.cardSaludo p{
  font: 2rem var(--fontGeneral);
}

.cardSaludo p span{
  color: var(--yellow);
  font: 1.8rem var(--fontGeneral);
  font-weight: 900;
}

.cardSaludo p label{
  font: 1.6rem var(--fontGeneral);
}

.cardProfile{
  width: 100%;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
}

.cardProCont{
  position: relative;
  border-radius: 10px;
  padding: 25px 15px;
  box-shadow: var(--shadow-card);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  color: var(--white);

  background: rgb(0,24,180);
  background: -moz-linear-gradient(180deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: linear-gradient(180deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0018b4",endColorstr="#035af4",GradientType=1);
  
  overflow: hidden;
}

.cardProCont div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 1;
}

.profileName{
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}
.profileRol{
  font: 0.9rem var(--fontGeneral);
  font-weight: 600;
  padding: 2px 8px;
  background: var(--white);
  color: var(--secondary);
  border-radius: 8px;
}

.svgform{
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

