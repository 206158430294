.cardTransfer{
	width: 100%;
	border-radius: 20px;
	padding: 32px 30px;
	background: var(--primary);
	box-shadow: var(--shadow-card);

	display: flex;
	flex-direction: column;
	gap: 15px;
}

.cardTransfer span{
  display: block;
  color: var(--white);
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
}

.cardTransfer p{
  color: var(--white);
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
}

.NTransfer p{
  color: var(--yellow);
  font: 2rem var(--fontGeneral);
  font-weight: 900;
}

.STransfer{
  display: flex;
  gap: 20px;
}

.STransfer p:first-child{
  padding-right: 20px;
  border-right: 1px solid var(--white);
}

.cardsDataUser{
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.cardDataUser{
  width: 30%;
  padding: 15px;
  border-radius: 10px;
  background: var(--card2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--textColor);
}

.cardDataUser h2{
  font: 1.8rem var(--fontGeneral);
  font-weight: 900;
  margin: 0;
}

.cardDataUser p{
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
}

.cardDataUser span{
  display: block;
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
}

.cardDataUser div{
  display: flex;
}