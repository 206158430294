.titleForm{
  font: 1.4rem var(--fontGeneral);
}

.cardsCountries{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardsCountries div{
  position: relative;
  border-radius: 10px;
}

.coinDivDisable::after{
  position: absolute;
  padding: 0px 5px;
  right: -5px;
  top: 10px;
  content: 'No Disponible';
  font: 1.2rem var(--fontGeneral);
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  z-index: 3;
}

.cardsCountries div button{
  padding: 20px;
  width: 100%;
  background: var(--grey2);
  color: var(--grayA);
  display: flex;
  align-items: center;
  gap: 10px;
  font: 1.4rem var(--fontGeneral);
  border-radius: 10px;
}

.cardsCountries button svg{
  width: 40px;
  height: 40px;
}

.cardsCountries button:hover{
  background: var(--primary-cl);
  color: var(--secondary);
}

.cardsCountries button:hover .arrowFlag{
  stroke: var(--secondary);
}

.arrowFlag{
  stroke: var(--grayA);
  width: 15px !important;
  height: 15px !important;
}

.cardsIndicator{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
  background: var(--primary-cl);
  border-radius: 10px;
}

.cardCountry{
  display: flex;
  align-items: center;
  gap: 5px;
  font: 1.4rem var(--fontGeneral);
  color: var(--secondary);
}

.cardCountry svg{
  width: 20px;
  height: 20px;
}

.cardCountry .arrowFlag{
  stroke: var(--secondary);
}

.cardAmmount{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid var(--secondary);
}

.cardAmmount p{
  font: 1.6rem var(--fontGeneral);
  font-weight: 600;
  color: var(--secondary);
}

.cardAmmount p span{
  display: block;
  font: 1.1rem var(--fontGeneral);
  font-weight: 400;
}