.divTableContent {
  padding-top: 5px;
}

.divTable-head {
  width: 100%;
  display: none;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--grey-lg);
  color: var(--grey);
}

.divTable-headRow {
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

#tableActions2 {
  width: 100% !important;
}

.divTable-headRow div {
  width: 20%;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
  padding: 0px 10px;
}

.divTable-body {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border-top: 1px solid var(--grey-lg);
  border-left: 1px solid var(--grey-lg);
  border-right: 1px solid var(--grey-lg);
  border-bottom: 1px solid var(--grey-lg);
  color: inherit;
  text-decoration-color: none;
  cursor: pointer;
}

.divTable-body:hover {
  background-color: #e8f7ff;
  color: var(--azul-lg);
  border-top: 1px solid var(--primary);
  border-left: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
}

.divTable-body:hover .tableActions button img {
  filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
}

.divTable-body:hover .divTable-row label {
  color: var(--azul-lg);
}

.divTable-row {
  display: flex;
  color: inherit;
  align-items: flex-start;
  padding: 10px 10px;
}

.divTable-row div {
  margin: 5px 0px;
  padding: 0 10px;
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
  overflow: hidden;
}

.divTable-row div a{
  color: var(--grey) !important;
}

#table5td {
  width: 100% !important;
  flex-wrap: wrap;
  padding: 10px 10px 0px 10px !important;
}

#table5td div {
  width: 50%;
}

#table9td {
  flex-wrap: wrap;
}

#table9td div {
  width: 50%;
}

#table6td {
  width: 100%;
  flex-wrap: wrap;
}

#table6td div {
  width: 50%;
}

.divTable-row label {
  display: block;
  font: 1.2rem var(--fontSt);
  font-weight: 700;
  color: var(--grey);
}

.tableActions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 10px 0;
}

.tableActions button {
  background: transparent;
  height: 50px;
  width: 50px;
  padding: 15px;
}

.tableActions button img {
  width: 100%;
}

.ColTdSm {
  display: none;
}

.td100 {
  width: 100% !important;
}

.tdNone {
  display: none;
}

.tdEmail {
  width: 100% !important;
}

.tdsal p{
  display: block;
  font: 0.9rem var(--fontGeneral) !important;
}

/*TESTIMONIOS*/

#tableRate {
  width: 100% !important;
  flex-wrap: wrap;
  padding: 10px 10px 10px 10px !important;
}

.tdComment {
  width: 100% !important;
}

.tdStars {
  width: 100% !important;
}

.tdStars .divStars {
  margin: 0 !important;
  padding: 0 !important;
  justify-content: start;
}

.tdStars .divStars .DivStar {
  padding: 0 !important;
}

.tdInfo {
  width: 50% !important;
}

/*CUENTAS*/

#tdCuentas {
  width: 100%;
  flex-wrap: wrap;
}

#tdCuentas div {
  width: 50% !important;
}

/*METODO*/
.tdmetodo p{
  background-color: var(--primary) !important;
}

.divReferencia{
  margin: 5px 0px;
  padding: 0 10px;
  overflow: visible !important;
}

.Tradicional{
  padding: 4px 6px !important;
  background: var(--primary);
  width: min-content !important;
  border-radius: 5px;
  color: #fff;
  font: 1rem var(--fontGeneral) !important;
}

.Referencia{
  padding: 4px 6px !important;
  background: var(--red);
  width: min-content !important;
  border-radius: 5px;
  color: #fff;
  font: 1rem var(--fontGeneral) !important;
}



@media (min-width: 576px) {
  #table5td div,
  #table9td div,
  #table6td div .td100 {
    width: 33.3%;
  }
  #table4td div {
    width: 33.3%;
  }
  .tdEmail {
    width: 50% !important;
  }

  /*TESTIMONIOS*/
  .tdStars,
  .tdInfo {
    width: 33.3% !important;
  }

  #tdCuentas div {
    width: 25% !important;
  }

}

@media (min-width: 768px) {
  .divTable-head {
    display: flex;
  }

  #tableActions2 {
    width: calc(100% - 100px) !important;
  }

  #tableActions2 div {
    width: 20%;
  }

  .divTable-body {
    flex-wrap: nowrap;
    border-top: 1px solid transparent;
    margin: 0px 0px;
    border-radius: 0px;
  }

  .divTable-body:last-child {
    border-radius: 0 0 10px 10px;
  }

  .divTable-row {
    align-items: center;
  }

  .divTable-row div {
    margin: 0px 0px;
  }

  .divTable-row label {
    display: none;
  }

  #table4td {
    width: calc(100% - 100px) !important;
    flex-wrap: wrap;
    padding: 10px !important;
  }

  #table4td {
    width: 20%;
  }

  #table5td {
    width: calc(100% - 100px) !important;
    flex-wrap: wrap;
    padding: 10px !important;
  }

  #table5td div {
    width: 20%;
  }

  #table9td {
    width: 100%;
  }

  #table9td div {
    width: 12.5%;
  }

  #table6td {
    width: 100%;
  }

  #table6td div {
    width: 14%;
  }

  .tdEmail {
    width: 30% !important;
  }

  .tableActions {
    width: inherit;
    padding: 0;
  }

  .tdNone {
    display: block;
  }

  /*TESTIMONIOS*/
  .tdComment {
    width: 40% !important;
  }

  .tdStars {
    width: 30% !important;
    text-align: center;
  }

  .tdStars .divStars {
    padding: 0 !important;
    justify-content: center;
  }

  .tdInfo {
    width: 15% !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .divTableContent {
    padding-top: 15px;
  }

  .tdSpecial {
    width: 12.4% !important;
  }

  .tdsal {
    text-align: right;
  }
}
