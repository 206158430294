.itemMenu{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.labelItemMenu{
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  border-radius: 10px;
  background: var(--grayA);
  color: var(--white);
  cursor: pointer;
}

.labelItemMenu span{
  font: 2rem var(--fontGeneral);
}

.labelItemMenu p{
  font: 1.2rem var(--fontGeneral);
}

.inputsMenu{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.inputsMenuNone{
  display: none;
}

.inputsMenu .input{
  flex-basis: calc(50% - 8px);
}

.inputsMenu .Selec{
  flex-basis: calc(50% - 8px);
}

.buttonsMenu{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
}
.labelMenu{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
}

.buttonAdd{
  border-radius: 10px;
  border: 1px dashed var(--grayA);
  padding: 10px;
  font: 1.4rem var(--fontGeneral);
  color: var(--grayA);
  display: flex;
  align-items: center;
  gap: 5px;
}

.buttonAdd svg{
  width: 18px;
  stroke: var(--grayA);
}

.itemsButtons{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttonItem{
  padding: 12px;
  border-radius: 10px;
  font: 1.4rem var(--fontGeneral);
  color: var(--grayA);
  display: flex;
  align-items: center;
  gap: 5px;
}