.AvatarBox{
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px;
}

.AvatarDrop{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.iconDrop{
  display: none !important;
}

.iconMenu{
  display: flex;
  height: 40px;
}

.iconMenu svg{
  width: 24px !important;
}

.spanName{
  display: none;
}

.spanName span{
  font: 10px var(--fontGeneral);
  font-weight: 400;
  line-height: 12px;
  color: var(--grayA);
}

.user-profile {
    position: relative;
    height: 60px;
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
  }
  
  .user-profile-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .photoName {
    display: flex;
    align-items: center;
  }
  
  .user-profile__photo {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: var(--gris-lg);
    overflow: hidden;
  }
  
  .user-profile__photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .user-profile-name {
    display: none;
    margin-left: 10px;
  }
  
  .user-profile-name p {
    font: 1.6rem var(--fontSt);
    font-weight: 600;
  }
  
  .user-profile-name span {
    font: 1.4rem var(--fontSt);
    font-weight: 300;
  }
  
  .boton-icono {
    display: none;
    background-color: transparent;
    padding: 10px;
  }
  
  .dropdownPerfil {
    position: absolute;
    top: 60px;
    left: 0;
    height: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: var(--blanco);
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 2px 7px 8px;
    overflow: hidden;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .dropdownPerfil a,
  .dropdownPerfil button {
    width: 100%;
    padding: 10px 20px;
    height: 50px;
    margin: 0;
    display: flex;
    align-items: center;
    background: transparent;
    color: var(--negro);
    font: 1.5rem var(--fontGeneral);
    text-align: left;
  }
  
  .dropdownPerfil a svg,
  .dropdownPerfil button svg {
    height: 20px;
    width: auto;
    margin-right: 10px;
    stroke: var(--icon);
  }
  
  .dropdownPerfil a:hover,
  .dropdownPerfil button:hover {
    color: var(--blanco);
    background: var(--secondary);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .dropdownPerfil a:hover img,
  .dropdownPerfil button:hover img {
    filter: invert(1) brightness(100%);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .dropdownAct {
    height: auto !important;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 999;
  }
  
  .dropLiA{
    color: var(--blanco) !important;
    background: var(--secondary) !important;
  }
  
  .dropLiA svg{
    stroke: var(--blanco);
  }
  
  @media (min-width: 576px) {
  }
  
  @media (min-width: 768px) {
  }
  
  @media (min-width: 992px) {

    .AvatarBox{
      height: 91px;
      padding: 0 30px;
    }
    
    .user-profile-name {
      display: block;
    }
  
    .boton-icono {
      display: block;
    }
  
    .user-profile__photo {
      height: 45px;
      width: 45px;
    }
  
    .user-profile {
      height: 80px;
    }
  
    .dropdownPerfil {
      top: 80px;
    }

    .spanName{
      display: flex;
      flex-direction: column;
      font: 12px var(--fontGeneral);
      font-weight: 700;
      line-height: 14px;
      color: var(--grayA);
      padding: 0;
    }

    .iconDrop{
      display: flex !important;
    }
    
    .iconMenu{
      display: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .user-profile {
      height: 90px;
    }
    .dropdownPerfil {
      top: 90px;
    }
  }
  
  @media (min-width: 1370px) {
    .user-profile {
      height: 100px;
    }
  
    .dropdownPerfil {
      top: 91px;
    }
  }
  