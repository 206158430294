.LoginPage{
  width: 100%;
  min-height: 100vh;
  padding: 60px 15px 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
}

.LoginContent{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.LoginDiv{
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  border-radius: 20px;
  background-color: var(--card);
  box-shadow: var(--shadow-card); 
  overflow: hidden;
}

.LoginNotice{
  position: relative;
  width: 100%;
  height: 150px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.LoginNotice img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.LoginBack{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.LoginBack img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LoginForm{
  padding: 50px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginForm form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.legalText{
  text-align: center;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
}

.legalText a{
  color: var(--secondary);
}

.headerLogin{
  text-align: center;
  color: var(--textColor);
  font: 1.4rem var(--fontGeneral);
}

.headerLogin h1{
  font: 2rem var(--fontGeneral);
  font-weight: 900;
  margin-bottom: 5px;
}

.headerLogin h2{
  font: 1.4rem var(--fontGeneral);
  font-weight: 400;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .LoginDiv{
    height: 600px;
    flex-wrap: nowrap;
  }

  .LoginNotice{
    width: 500px;
    height: 100%;
    padding: 80px;
  }

  .LoginForm{
    padding: 100px;
    width: 600px;
  }
}

@media (min-width: 1200px) {
}
