.input,
.inputDisabled,
.inputPhone,
.inputButton,
.inputCoin,
.inputCoinNew{
    position: relative;
    width: 100%;
}

.inputButton,
.inputPhone{
    display: flex;
}

input::selection { 
    background: var(--primary-cl);
    color: var(--secondary);
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background: var(--primary-cl);
    color: var(--secondary);
    outline: none;
}

.inputCodeAuth{
    width: 100%;
    position: relative;
    display: flex !important;
    flex-direction: row;
    gap: 5px;
}
.inputCodeAuth input{
    position: relative;
    height: 40px;
    width: 35px;
    padding: 0px;
    border-radius: 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 600;
    text-align: center;
}
.inputCodeAuth::before{
    display: none;
}

.input input,
.input textarea{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.input textarea{
    resize: vertical;
    min-height: 40px;
    height: 100px;
}

.inputButton input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 40px;
    border-radius: 10px 0 0 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.inputButtonNormal input{
    padding: 6px 12px 6px 12px;
}

.inputPhone input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 90px;
    border-radius: 10px 10px 10px 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.inputIco input{
    padding: 6px 12px 6px 40px;
}

.inputCodeAuth:hover input,
.input:hover input,
.inputButton:hover input,
.inputPhone:hover input,
.input:hover textarea {
    border: solid 1px var(--secondary) !important;
    box-shadow: var(--cardShadowLg);
}

.inputPhone:hover label{
    color: var(--secondary);
}

.inputCodeAuth input:focus,
.input input:focus,
.inputButton input:focus,
.inputPhone input:focus,
.input textarea:focus {
    outline: none;
    border: solid 1px var(--secondary);
    box-shadow: none;
}

.input label{
    position: absolute;
    top: 10px;
    left: 5px;
    margin-left: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: var(--grey-md);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.inputIco label{
    left: 30px;
}

.inputButton label{
    position: absolute;
    top: 10px;
    left: 30px;
    margin-left: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: var(--grey-md);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.inputButtonNormal label{
    left: 5px;
}

.inputPhone label{
    position: absolute;
    top: -8px;
    left: -3px;
    margin-left: 10px;
    padding: 0px 6px;
    background: var(--card);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: var(--grey-md);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
    pointer-events: none;
    z-index: 2;
}


.input input:focus + label,
.input textarea:focus + label,
.inputButton input:focus + label,
.inputPhone input:focus + label {
    top: -8px;
    left: -3px;
    padding: 0px 6px;
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

.inputCodeAuth input:not(:placeholder-shown),
.input input:not(:placeholder-shown),
.input textarea:not(:placeholder-shown),
.inputButton input:not(:placeholder-shown),
.inputPhone input:not(:placeholder-shown)  {
    outline: none;
    border: solid 1px var(--secondary);
    background: var(--card);
}

.input input:not(:placeholder-shown) + label,
.input textarea:not(:placeholder-shown) + label,
.inputButton input:not(:placeholder-shown) + label {
    top: -8px;
    left: -3px;
    padding: 0px 6px;
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

.inputPhone input:not(:placeholder-shown) + label  {
    top: -8px;
    left: -3px;
    padding: 0px 6px;
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

.inputButton button{
    padding: 15px 20px;
    height: 40px;
    border-radius: 0px 10px 10px 0px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    
    color: #fff;
    font: 1.5rem var(--fontGeneral), sans-serif;
    font-weight: 400;
    white-space: nowrap;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.inputButton button svg{
    stroke: var(--white);
    height: 20px;
}

.ValueCheck{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    left: 13px;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    pointer-events: none;
    z-index: 15;
    stroke: var(--green);
    background: var(--green-lg);
}

.ValueNone{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    left: 13px;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    pointer-events: none;
    z-index: 15;
    stroke: var(--grey);
    background: var(--grey-lg);
}

.inputNoData input{
    border: solid 1px var(--grey-md) !important; 
}

.inputNoData label{
    color: var(--grey-md) !important;
}

.inputNoData input:focus{
    outline: none;
    border: solid 1px var(--secondary) !important;
    box-shadow: none;
}

.inputNoData input:hover + label{
    color: var(--secondary) !important;
}

.inputNoData input:focus + label{
    color: var(--secondary) !important;
}

.inputIco svg{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    left: 12px;
    cursor: pointer;
    pointer-events: none;
    z-index: 1;
    stroke: var(--icon);
}

.inputDate svg {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    right: 13px;
    cursor: pointer;
    pointer-events: none;
    z-index: 1;
    stroke: var(--icon);
}

.inputDate::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 12px;
    height: 26px;
    width: 26px;
    background-color: var(--card);
    pointer-events: none;
}

.inputIcon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px 12px 0px 10px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.inputIcon svg{
    stroke: var(--icon);
    opacity: 0.3;
    width: 24px;
}

.dotsPass{
    font-weight: 900 !important;
}

.inputCoin input{
    padding: 6px 12px 6px 40px;
}

.inputCoin svg{
    position: absolute;
    top: 7px;
    left: 7px;
    height: 26px;
    width: 26px;
}


.redLine input{
  border: solid 1px var(--red) !important;
}

.redLine:hover input{
    border: solid 1px var(--red) !important;
    box-shadow: var(--ShadowRed) !important;
  }

.redLine input:not(:placeholder-shown){
  border: solid 1px var(--red) !important;
}

.redLine input:not(:placeholder-shown) + label{
  color: var(--red) !important;
}

.spanAlert {
    position: absolute;
    bottom: -10px;
    left: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    background: var(--red-lg);
    color: var(--red);
    font: 1.1rem var(--fontGeneral);
    font-weight: 500;
    z-index: 1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.inputDisabled input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--primary-cl);
    color: var(--secondary);
    border: solid 1px var(--secondary);
    box-shadow: none;
    font: 1.6rem var(--fontGeneral);
    font-weight: 600;
}

.inputGrey input{
    background: var(--grey) !important;
}

.inputGrey label{
    color: red;
}

.inputDisabled label {
    position: absolute;
    top: 10px;
    left: -3px;
    padding: 0px 6px;
    margin-left: 10px;
    border-radius: 5px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background: var(--primary-cl);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
    pointer-events: none;
    z-index: 2;
}

.redLine label{
 color: var(--red) !important;
}


.RadioList{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

/*INPUT COIN*/
.inputCoinNew input{
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    background: var(--card);
    color: var(--textColor);
    border: solid 1px var(--grey-md);
    box-shadow: var(--shadow-card);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
}

.inputCoinNew input:hover{
    border: solid 1px var(--secondary) !important;
    box-shadow: var(--cardShadowLg);
}

.inputCoinNew input:focus {
    outline: none;
    border: solid 1px var(--secondary);
    box-shadow: none;
}

.inputCoinNew label{
    position: absolute;
    top: 10px;
    left: 5px;
    margin-left: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: var(--grey-md);
    font: 1.6rem var(--fontGeneral);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.inputCoinNew input:not(:placeholder-shown)  {
    outline: none;
    border: solid 1px var(--secondary);
    background: var(--card);
}
.inputCoinNew input:not(:placeholder-shown) + label {
    top: -8px;
    left: -3px;
    padding: 0px 6px;
    background: var(--card);
    color: var(--secondary);
    font: 1.2rem var(--fontGeneral);
    font-weight: 600;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
