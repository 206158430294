.tg01{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 2px;
    
    height: 26px;
    width: 44px;
    border-radius: 70px;

    background-color: var(--grey);
}

.tg01:hover{
    background-color: var(--primary-cl);
    box-shadow: var(--BoxShadowB);
}

.tg01:hover div{
    background-color: var(--secondary);
}

.tg01 div{
    box-sizing: border-box;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    background: var(--white);
}

.tg01A{
    justify-content: right !important;
    background-color: var(--primary);
}

.tg01A div{
    background: var(--white);
}