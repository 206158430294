.payTitle{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  border: 1px solid var(--primary-lg);
  background-color: var(--primary-cl);
  border-radius: 15px;
  padding: 20px;
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
  color: var(--primary-lg);
}

.payTitle span{
  display: block;
  font-weight: 400;
}

.payTitle svg{
  width: 20px;
}

.pay10{
  display: flex;
  gap: 10px;
}

.pay10 div{
  flex-basis: 100%;
  border: 1px solid var(--grey);
  padding: 20px;
  border-radius: 15px;
  font: 1.4rem var(--fontGeneral);
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--textColor);
}

.pay10 span{
  display: block;
  font: 1rem var(--fontGeneral);
  font-weight: 600;
  color: var(--grey1);
}

.payNumber10{
  padding: 10px;
  background: var(--grey);
}

.payInvoiceFalse{
  display: flex;
}

.payInvoice{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed var(--grey1) !important;
}

.payInvoice p{
  max-width: 150px;
}

.payTicket{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid var(--primary-lg);
  background-color: var(--primary-cl);
  border-radius: 15px;
  padding: 10px 20px;
}

.payTicket a{
  font: 1.4rem var(--fontGeneral);
  font-weight: 600;
  color: var(--primary-lg);
}

.payTicket svg{
  stroke: var(--primary-lg);
  width: 20px;
}