.rowInput2{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.textP{
  font: 1.4rem var(--fontGeneral);
  color: var(--textColor);
}

.react-multiple-carousel__arrow{
  z-index: 1;
}

.pElipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .rowInput2{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
  }
}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {
  
}