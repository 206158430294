.walletsBox{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.walletsDiv{
  padding: 10px;
  border: 1px solid red;
  border-radius: 10px;
}

.cardWallet{
  width: 100%;
	
  background: rgb(0,24,180);
  background: -moz-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  background: linear-gradient(90deg, rgba(0,24,180,1) 0%, rgba(3,90,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0018b4",endColorstr="#035af4",GradientType=1);

	border-radius: 20px;
	padding: 32px 30px;
	box-shadow: var(--shadow-card);

	display: flex;
	flex-direction: column;
	gap: 15px;
}

.cardBalance{
  color: var(--textColor);
  text-align: center;
}

.cardBalance label{
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
  color: var(--white);
}

.cardBalance span{
  color: var(--white);
  display: block;
  font: 2.8rem var(--fontGeneral);
  font-weight: 700;
}

.walletActions{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.walletActions a{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 160px;
  padding: 10px;
  background-color: var(--card2);
  border-radius: 10px;
  font: 1.2rem var(--fontGeneral);
  font-weight: 400;
  color: var(--textColor);
}

.walletActions svg{
  width: 25px;
  height: 25px;
  stroke: var(--textColor);
}

.walletActCont{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.walletAct{
  cursor: pointer;
  padding: 15px;
  background:  var(--card2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--textColor);
}

.walletAct:hover{
  background:  var(--secondary);
  color: var(--white) !important;
}

.walletAct:hover .colorRed{
  color: var(--white) !important;
}

.walletAct:hover svg{
  stroke: var(--white);
}

.walletAct svg{
  width: 25px;
  height: 25px;
  stroke: var(--textColor);
}

.cardCreate{
  width: 100%;
	background: var(--primary);
	border-radius: 20px;
	padding: 50px 30px;
	box-shadow: var(--shadow-card);

	display: flex;
	flex-direction: column;
  align-items: center;
	gap: 15px;
}

.cardCreate p{
  max-width: 450px;
  text-align: center;
  color: var(--white);
  font: 1.4rem var(--fontGeneral);
}

.cardCreate p a{
  color: var(--yellow);
}

.btnHistory{
  display: flex;
  gap: 5px;
}

.btnHistory button{
  padding: 5px 15px;
  border-radius: 12px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: transparent;
  border: 1px solid var(--grey);
  color: var(--grey);
  font: 1.4rem var(--fontGeneral), sans-serif;
  font-weight: 400;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btnHistory button:focus{
  background: var(--primary-cl);
  border: 1px solid var(--primary-lg) !important;
  color: var(--primary-lg);
}

.movInfo{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.movInfo div{
  display: flex;
  flex-direction: column;
}

.movAmmount{
  font: 1.6rem var(--fontGeneral);
  font-weight: 600;
}

.movData span{
  font: 1rem var(--fontGeneral);
  font-weight: 400;
}

.movData label{
  font: 1.2rem var(--fontGeneral);
  font-weight: 600;
}



@media (min-width: 576px) {
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {
  
}